<template>
    <div class="contextBar">
        <div class="backButton" @click="this.$emit('back')">
            <i class="fi fi-sr-angle-left"></i>
        </div>
        <p class="currentView">Suggested robot</p>
    </div>

    <div class="suggested-robot">
        <div class="user-info">
            <p class="title">User Info</p>
            <p>Name: {{this.robotData.user.name}}</p>
            <p>Email: {{this.robotData.user.email}}</p>
            <p>Phone: {{this.robotData.user.phone}}</p>
        </div>

        <div class="user-info">
            <p class="title">Suggested Robot Info</p>
            <p>Technologies: {{this.robotData.technologies.toString()}}</p>
            <p>Description: {{this.robotData.description}}</p>
        </div>
    </div>

</template>

<script>
export default {
    props: [ 'robotData' ],

    data:function(){
        return{

        }
    },
    
}
</script>

<style>

.suggested-robot{
    display:flex;
    flex-direction: column;
    gap:8px;
    padding: 24px;
    margin: 24px;
    border-radius: 4px;
    background: white;
    

}
.user-info{
    display: flex;
    flex-direction: column;
    gap:4px;
    user-select: text;
}
.user-info .title{
    font-weight: bold;
    font-size: 20px;

}
</style>