<template>
    <div class="loginContainer">

        <div class="signInUpContainer">

            <div class="imgContainer"><img src="@/assets/slang/slang_logo.png"></div>
            <div class="formContainer">
                
                <div class="formInputContainer">
                    <p class="label">Email:</p>
                    <div class="formInput">
                        
                        <input class="formInput" type="text" placeholder="Enter your email" spellcheck="false" v-model="this.email" v-on:keydown.enter="this.Auth()">
                    </div>
                </div>

                <div class="formInputContainer">
                    <p class="label">Password:</p>
                    <div class="formInput">
                        <input class="formInput" type="password" placeholder="Enter your password" spellcheck="false" v-model="this.password" v-on:keydown.enter="this.Auth()">
                    </div>
                </div>

                <div class="recatpchaContaier" v-show="this.viewRecaptcha">
                    <vue-recaptcha sitekey="6Lf03GsgAAAAAHCVAgKuF5pBtc9WuVoQvw12vEy-"
                        size="normal" 
                        theme="light"
                        hl="en"
                        @verify="recaptchaVerified"
                        @expire="recaptchaExpired"
                        @fail="recaptchaFailed"
                        ref="vueRecaptcha">
                    </vue-recaptcha>

                </div>
                
                <button class="generalButton primary" style="width: 100%;margin:16px 0; font-size: 16px" @click="this.Auth()">
                    <p >Login</p>
                </button>

            </div>
        </div>
    </div>
</template>

<script>
import vueRecaptcha from 'vue3-recaptcha2';
import { UserSignIn } from '../helpers/APIconnection'

export default {
    components: {vueRecaptcha},
   
    data: function() {

        return {

            email:"",
            password:"",
            viewRecaptcha:false,
            recaptchaAutoriced:false
        }
    },

    methods: {


        Auth: async function(){

            if ( !this.email || !this.password ) { 
                this.viewRecaptcha=true
                this.$refs.vueRecaptcha.reset();
                this.recaptchaAutoriced=false;
                return } 
            
            if(this.recaptchaAutoriced==true || this.viewRecaptcha==false){
                
                const response = await UserSignIn(this.email, this.password, undefined)
                
                if ( response.status === 200 ) { this.$router.replace('users') }
                // UserSignIn(this.email, this.password, undefined).then(() =>{
                //     this.$router.replace('users')
                // })
                this.viewRecaptcha=true
                this.$refs.vueRecaptcha.reset();
                this.recaptchaAutoriced=false;
                

            }
            

        },
        recaptchaVerified() {
            this.recaptchaAutoriced=true
        },
        recaptchaExpired() {
            this.$refs.vueRecaptcha.reset();
            this.recaptchaAutoriced=false
        },
        recaptchaFailed(response) {
            console.log(response)
        }
    },
}
</script>

<style>
.loginContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: var(--gradient-h-c-s);
    
}

.signInUpContainer{
    
    border-radius: 16px;
    max-width: 500px;
    width: 50%;
    background: white;
    padding:40px 32px;
    transition-duration: 2s;
    transition:ease-in
    
    
}

.signInUpContainer .imgContainer{

    
    display: flex;
    justify-content: center;
}

.signInUpContainer .formContainer{
    color: var(--main-100);
    
}
.formContainer .formTitle{
    text-align: center;
    font-size: 32px;
}
.formContainer .formInputContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 12px;
}

.formContainer .formInputContainer .formInput input{

    width: 100%;
    padding: 12px;
    border-radius: 8px;
    outline: none;
    background: var(--main-10);
    color: var(--main-100);
}

.label {
    font-size: 16px; font-weight: 600
}

.recatpchaContaier{
    display: flex;
    justify-content: center;
    padding: 8px;
    
}
</style>