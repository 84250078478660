<template>
    <div v-if="this.viewing === undefined">
        <div class="contextBar">
            <p class="currentView">{{ this.$route.name }}</p>

            <!--Para que aparezca cuantos errores totales (Objetos)-->
            <div class="titleInfoTagsContainer" style="margin-left: 24px" v-if="this.result">
                <div class="titleInfoTags">
                    {{ this.result.total }} logs
                </div>
            </div>
        </div>
        <div class="usersListContainer" v-if="!this.loader">
            <div style="display: flex; gap: 10px; padding: 4px;">
                <span style="padding: 4px; border-radius: 8px; cursor: pointer;" :style="{ background: this.activeTab === 'flowsSummary' ? 'lime' : '' }" @click="this.activeTab = 'flowsSummary'">Flows summary</span>
                <span style="padding: 4px; border-radius: 8px; cursor: pointer;" :style="{ background: this.activeTab === 'detailedList' ? 'lime' : '' }" @click="this.activeTab = 'detailedList'">Detailed list</span>
            </div>
            <div v-if="this.activeTab === 'flowsSummary'">
                <div>Total flows found: {{ this.flowsSummary.total }} <label style="margin-left: 20px;"><input type="checkbox" :checked="this.showInvalidFlows" @click="this.showInvalidFlows = !this.showInvalidFlows"/> Show invalid flows</label></div>
                <div style="display: grid; gap: 10px 20px; grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));">
                    <template v-for="flowSummary in this.flowsSummary.items" :key="flowSummary">
                        <div v-if="this.showInvalidFlows || Object.keys(flowSummary)[0].length && this.possibleFlows.filter(elem => Object.hasOwn(flowSummary, elem[0]._id))[0]?.[0]?.name" style="display: flex; flex-direction: column; height: 250px;">
                            <p style="font-weight: bold; font-size: 1.25rem; text-wrap: balance;" :style="{ color: Object.keys(flowSummary)[0].length && this.possibleFlows.filter(elem => Object.hasOwn(flowSummary, elem[0]._id))[0]?.[0]?.name ? '' : 'color-mix(in srgb, red 75%, currentColor)' }" :title="Object.keys(flowSummary)[0]">{{ Object.keys(flowSummary)[0].length ? this.possibleFlows.filter(elem => Object.hasOwn(flowSummary, elem[0]._id))[0]?.[0]?.name ?? '(Deleted)' : '(Unspecified)' }}</p>
                            <span style="color: hsl(30deg 80% 40%)">Count: {{ Object.values(Object.values(flowSummary)[0]).map(value => Object.values(value)[0].count).reduce((accu, curr) => accu + curr) }}</span>
                            <div style="height: 0; flex-grow: 1; overflow: auto;">
                                <table>
                                    <tbody>
                                        <tr v-for="day of [ ...Object.values(flowSummary)[0] ].reverse()" :key="day">
                                            <td>{{ FormatDate(Object.keys(day)[0]) }}</td>
                                            <td style="text-align: right;">{{ Object.values(day)[0].count }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-for="intermediate of [
                                (() => {
                                    const fields = Object.values(flowSummary)[0].map(e => Object.entries(e).map(f => [ f[0], f[1].count ])).flat().reduce((accu, curr) => {
                                        const n = +curr[0].substring(0, 4) * 12 + +curr[0].substring(5, 7);
                                        accu.data[n] ??= 0;
                                        accu.data[n] += curr[1];
                                        if (accu.data[n] > accu.top) {
                                            accu.top = accu.data[n];
                                        }
                                        if (n > accu.max) {
                                            accu.max = n;
                                        }
                                        if (n < accu.min) {
                                            accu.min = n;
                                        }
                                        return accu;
                                    }, { data: [], max: new Date().getFullYear() * 12 + new Date().getMonth() + 1, min: Number.MAX_SAFE_INTEGER, top: 0 });
                                    fields.data = [ ...fields.data.slice(fields.min) ].map((e, i) => [ ((i + fields.min - 1) / 12 | 0) + '-' + (((i + fields.min - 1) % 12 + 1 > 9 ? '' : '0') + ((i + fields.min - 1) % 12 + 1)), e || 0 ]);
                                    const scaleLevel = Math.max((Math.log10(fields.top) * 3 | 0) - 1, 0);
                                    fields.scale = 10 ** (scaleLevel / 3 | 0) * [1, 2, 5][scaleLevel % 3];
                                    return fields;
                                })()
                            ]" :key="intermediate">
                                <svg style="width: 100%; height: 52px;">
                                    <line v-for="height of (intermediate.top / intermediate.scale) | 0" :key="height" x1="0%" :title="height * intermediate.scale" :y1="(intermediate.top - height * intermediate.scale) * 50 / intermediate.top + 1" x2="100%" :y2="(intermediate.top - height * intermediate.scale) * 50 / intermediate.top + 1" stroke="#ccc"/>
                                    <line v-for="index of intermediate.max - intermediate.min + 1" :key="index"
                                        :x1="((index - 1) * 100 / (intermediate.max - intermediate.min)) + '%'"
                                        :y1="(intermediate.top - (intermediate.data[index - 1]?.[1] ?? 0)) * 50 / intermediate.top + 1"
                                        :x2="(index * 100 / (intermediate.max - intermediate.min)) + '%'"
                                        :y2="(intermediate.top - (intermediate.data[index]?.[1] ?? 0)) * 50 / intermediate.top + 1"
                                        stroke="#00f"/>
                                    <text x="0" :y="(intermediate.top - intermediate.scale) * 50 / intermediate.top + 12" stroke="green">{{ intermediate.scale }}</text>
                                    <text x="0" :y="12" stroke="green">{{ intermediate.top }}</text>
                                </svg>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <div v-else-if="this.activeTab === 'detailedList'">
                <div class="responseConfigBar">
                    <div class="searchBar">
                        <i class="fi fi-rr-search"></i>
                        <input type="text" spellcheck="false" placeholder="Search error or user" v-model="this.searchText"
                            @input="this.Search()" @focus="$event.currentTarget.parentElement.classList.add('focus')"
                            @blur="$event.currentTarget.parentElement.classList.remove('focus')">
                    </div>
                    <div style="display: flex; flex-direction: row; gap: 5px;">
                        <div class="filters" >
                            <button class="filtersButton" @click="this.GetFlowsReport()">
                                Download Report
                            </button>
                        </div>
                        <div class="filters">
                            <button class="filtersButton" @click="this.showFiltersContainer = !this.showFiltersContainer">
                                <i class="fi fi-rr-filter"></i>
                                Filters
                            </button>

                            <div class="filtersContainer" v-if='this.showFiltersContainer'>
                                <p class='filtersTitle'>Filters</p>

                                <div class="filterLine">
                                    <div class="filter">
                                        <div class="textFlex">
                                            <p> Created</p>
                                            <p class="spec">From</p>
                                        </div>
                                        <input type="date" v-model="this.filterValues.dateFrom"
                                            v-on:keydown.enter="this.paginationValues.entriesStart = 0; this.Search()">
                                    </div>

                                    <div class="filter">
                                        <div class="textFlex end">
                                            <p class="spec">To</p>
                                        </div>
                                        <input type="date" placeholder="01/03/2022" v-model="this.filterValues.dateTo"
                                            v-on:keydown.enter="this.paginationValues.entriesStart = 0; this.Search()">
                                    </div>

                                </div>

                                <CheckableList listTitle="Users" :listOptions="this.possibleUsers" v-slot="slotProps" @optionChanged="index => { this.possibleUsers[index][1] = !this.possibleUsers[index][1]; this.Search(); }" @optionsCleared="this.Search()" :toFilter="element => [ element.name, element.email ]">
                                    <div :title="slotProps.element._id + ' @' + slotProps.index" style="padding: 2px 4px;" :style="{ background: slotProps.status ? '#fff' : '#ccc' }">
                                        <p style="color: hsl(300, 100%, 30%); text-decoration: underline;">{{ slotProps.element.name }}</p>
                                        <p>{{ slotProps.element.email }}</p>
                                    </div>
                                </CheckableList>

                                <CheckableList listTitle="Flows" :listOptions="this.possibleFlows" v-slot="slotProps" @optionChanged="index => { this.possibleFlows[index][1] = !this.possibleFlows[index][1]; this.Search(); }" @optionsCleared="this.Search()" :toFilter="element => [ element.name, element.group?.name ?? '' ]">
                                    <div :title="slotProps.element._id + ' @' + slotProps.index" style="padding: 2px 4px;" :style="{ background: slotProps.status ? '#fff' : '#ccc' }">
                                        <p style="color: hsl(300, 100%, 30%); text-decoration: underline;">{{ slotProps.element.name }}</p>
                                        <p :style="{ color: slotProps.element.group?.name ? '' : 'gray' }">{{ slotProps.element.group?.name ?? '(No group)' }}</p>
                                    </div>
                                </CheckableList>

                                <div class="addFiltersButton">
                                    <button class="generalButton primary" @click="this.paginationValues.entriesStart = 0; this.Search()">Search</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <TableHeader :paginationValues="this.paginationValues" :entriesTotal="this.result.total"
                            v-on:OnPaginationChange="Object.assign(this.paginationValues, $event); this, Search()" />
                <table class="usersTable">
                    <thead>
                        <tr>
                            <th><div :class="this.sortValues['createAt']" @click="this.ChangeSorting('createAt')">At <i class="fi fi-br-arrow-down"></i></div></th>
                            <th><div :class="this.sortValues['user.name']" @click="this.ChangeSorting('user.name')">User <i class="fi fi-br-arrow-down"></i></div></th>
                            <th><div :class="this.sortValues['user.email']" @click="this.ChangeSorting('user.email')">Email <i class="fi fi-br-arrow-down"></i></div></th>
                            <th>Subscription</th>
                            <th>Flow group</th>
                            <th><div :class="this.sortValues['browserFlow.name']" @click="this.ChangeSorting('browserFlow.name')">Flow <i class="fi fi-br-arrow-down"></i></div></th>
                            <th>Result</th>
                            <th><div :class="this.sortValues['successfulIterations']" @click="this.ChangeSorting('successfulIterations')">OKs <i class="fi fi-br-arrow-down"></i></div></th>
                            <th><div :class="this.sortValues['failedIterations']" @click="this.ChangeSorting('failedIterations')">Fails <i class="fi fi-br-arrow-down"></i></div></th>
                            <th><div :class="this.sortValues['totalIterations']" @click="this.ChangeSorting('totalIterations')">Total <i class="fi fi-br-arrow-down"></i></div></th>
                            <th><div :class="this.sortValues['successRate']" @click="this.ChangeSorting('successRate')">Rate <i class="fi fi-br-arrow-down"></i></div></th>
                            <th>Output files</th>
                            <th>Errors</th>
                            <th>Log streams</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="element of this.result.items" :key="element" @click="this.ViewLog(element)">
                            <td>{{ FormatDate(element.createAt, true) }}</td>
                            <td>{{ element.user?.name ?? '-' }}</td>
                            <td>{{ element.user?.email ?? '-' }}</td>
                            <td>{{ element.subscriptionEmail ?? '-' }}</td>
                            <td>{{ element.browserFlow?.group?.name ?? '-' }}</td>
                            <td>{{ element.browserFlow?.name ?? '-' }}</td>
                            <td>{{ element.result }}</td>
                            <td>{{ element.successfulIterations }}</td>
                            <td>{{ element.failedIterations }}</td>
                            <td>{{ element.totalIterations }}</td>
                            <td class="quick-graph" :style="{ '--ok': element.successfulIterations, '--total': element.totalIterations || 1 }">{{ Intl.NumberFormat(undefined, { style: 'percent', maximumFractionDigits: 0 }).format(element.successRate) }}</td>
                            <td>{{ element.outputFileNames }}</td>
                            <td>{{ element.errorList }}</td>
                            <td>{{ Object.entries(element.logStreams).map(([ k, v ]) => k + ':' + v).join(' ') }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div v-else class="bigLoadingSpinner main center"></div>
    </div>
    <BrowserFlowLogsView v-else :viewingData="this.viewing" @back="this.viewing = undefined; this.$backToPreviousScroll()" />
</template>
<script>
import { SearchQuery, GetBrowserFlowsReport } from '../helpers/APIconnection';
import TableHeader from '@/components/TableHeader';
import { FormatDate, SwitchFieldSorting, Download } from '@/helpers/utils';
import BrowserFlowLogsView from '@/components/BrowserFlowLogsView';
import CheckableList from '@/components/CheckableList';
import { Buffer } from 'buffer';

export default {
    components: { TableHeader, BrowserFlowLogsView, CheckableList },
    data: function() {
        return {
            filterValues: {
                dateFrom: undefined,
                dateTo: undefined,
            },
            searchText: '',
            sortValues: { createAt: -1 },
            paginationValues: {
                entriesStart: 0,
                entriesLimit: 100,
            },
            result: {
                total: 0,
                items: [],
            },
            flowsSummary: {
                total: 0,
                items: [],
            },
            viewing: undefined,
            loader: true,
            activeTab: 'detailedList',
            showFiltersContainer: false,
            searchTimeOut: undefined,
            possibleUsers: [],
            possibleFlows: [],
            showInvalidFlows: false,
        };
    },
    created() {
        this.Search();
        this.GetUsers();
        this.GetFlows();
        this.GetFlowsSummary();
    },
    methods: {
        FormatDate,
        SwitchFieldSorting,
        Search: function() {
            clearTimeout(this.searchTimeOut);
            this.searchTimeOut = setTimeout(() => {
//                this.loader = true;
                const searchQuery = this.FillSearchQuery(SearchQuery({ collection: 'browserflowlogs.$list', sort: this.sortValues, start: this.paginationValues.entriesStart, limit: this.paginationValues.entriesLimit }));
                searchQuery.then((response) => {
                    this.result = response.data;
                    this.loader = false;
                }).catch((error) => {
                    console.error(error);
                });
            }, 500);
        },
        GetUsers: function(start = 0, limit = 100) {
            if (!start) {
                this.possibleUsers = [];
            }
            SearchQuery({ collection: 'users.$name', sort: {}, start, limit })``.then((response) => {
                this.possibleUsers.splice(this.possibleUsers.length, 0, ...response.data.items.map(elem => [ elem, false ]));
                if (this.possibleUsers.length < response.data.total) {
                    this.GetUsers(this.possibleUsers.length, 100);
                }
            }).catch((error) => {
                console.error(error);
            });
        },
        GetFlows: function(start = 0, limit = 100) {
            if (!start) {
                this.possibleFlows = [];
            }
            SearchQuery({ collection: 'browserflows.$name', sort: {}, start, limit })``.then((response) => {
                this.possibleFlows.splice(this.possibleFlows.length, 0, ...response.data.items.map(elem => [ elem, false ]));
                if (this.possibleFlows.length < response.data.total) {
                    this.GetFlows(this.possibleFlows.length, 100);
                }
            }).catch((error) => {
                console.error(error);
            });
        },
        ChangeSorting: function(field) {
            this.SwitchFieldSorting(this.sortValues, field);
            this.paginationValues.entriesStart = 0;
            this.Search();
        },
        ViewLog: function(element) {
            this.$saveCurrentScroll();
            this.viewing = element;
            SearchQuery({ collection: 'browserflowlogs', sort: this.sortValues, start: 0, limit: 1 })`_id=#${element._id}`.then((response) => {
                if (response.data.total) {
                    this.viewing = response.data.items[0];
                }
            }).catch((error) => {
                console.error(error);
            });
        },
        GetFlowsSummary: function() {
            SearchQuery({ collection: 'browserflowlogs.$flowsPerDay', sort: this.sortValues, start: 0, limit: 100 })``.then((response) => {
                this.flowsSummary = response.data;
            }).catch((error) => {
                console.error(error);
            });
        },
        GetFlowsReport: async function() {
            const searchQuery = await this.FillSearchQuery(SearchQuery({ sort: this.sortValues }));
            const response = await GetBrowserFlowsReport(searchQuery)
            if (response?.status === 200 && response?.data?.length !== 0) {
                Download({blob: new Blob([Buffer.from(response.data, "base64")], { type: "application/vnd.ms-excel" }), filename: "reporteSlango.xlsx"})
            }
        },
        FillSearchQuery: function(searchQuery) {
            const dateFrom = this.filterValues.dateFrom ? new Date(this.filterValues.dateFrom) : undefined;
            const dateTo = this.filterValues.dateTo ? new Date(this.filterValues.dateTo) : undefined;
            if (dateTo) {
                dateTo.setDate(dateTo.getDate() + 1);
            }
            if (this.searchText) {
                searchQuery = searchQuery`search=${this.searchText}`;
            }
            if (dateFrom || dateTo) {
                searchQuery = searchQuery`createAt:${dateFrom}..${dateTo}`;
            }
            const userIDs = this.possibleUsers.filter(elem => elem[1]).map(elem => elem[0]._id);
            if (userIDs.length) {
                searchQuery = searchQuery`user._id:#${userIDs}`;
            }
            const flowsIDs = this.possibleFlows.filter(elem => elem[1]).map(elem => elem[0]._id);
            if (flowsIDs.length) {
                searchQuery = searchQuery`browserFlow._id:#${flowsIDs}`;
            }
            return searchQuery;
        },
    },
}
</script>

<style scoped>
table {
    width: 100%;
    border-collapse: collapse;
}
td.quick-graph {
    --percent: calc(var(--ok) / var(--total) * 100%);
    position: relative;
    &::before {
        content: " ";
        display: inline-block;
        border-radius: 100%;
        width: 15px;
        aspect-ratio: 1 / 1;
        vertical-align: middle;
        margin-right: 5px;
        background: conic-gradient(hsl(120deg 100% 70%) var(--percent), hsl(0deg 100% 70%) var(--percent));
        position: absolute;
        top: 50%;
        left: 0%;
        transform: translate(0%, -50%) translateX(50px);
    }
}
td {
    padding: 2px;
}
tbody > tr:hover {
    background-color: hsl(180deg 30% 90%);
}
</style>