<template>
  <div>
    <div class="contextBar">
            <p class="currentView">{{this.$route.name}}</p>

            <div class="titleInfoTagsContainer" style="margin-left: 24px">
                <div class="titleInfoTags" v-if="this.companies">
                    {{this.companies.total}} companies
                </div>
            </div>
        </div>

    <div class="usersListContainer" v-if="!this.loader">

        <div class="responseConfigBar">
                <div class="searchBar">
                    <i class="fi fi-rr-search"></i>
                    <input type="text" spellcheck="false" placeholder="Search company" v-model="this.searchText" @input="this.SearchAppCompany()" @focus="$event.currentTarget.parentElement.classList.add('focus')" @blur="$event.currentTarget.parentElement.classList.remove('focus')">
                </div>
                <div class="filters" >
                    <button class="filtersButton" @click="this.showFiltersContainer = !this.showFiltersContainer">
                        <i class="fi fi-rr-filter"></i>
                        Filters
                    </button>

                    <div class="filtersContainer" v-if='this.showFiltersContainer'>
                        <p class='filtersTitle'>Filters</p>

                        <div class="filterLine">
                            <div class="filter">
                                <div class="textFlex">
                                    <p> Created</p>
                                    <p class="spec">From</p>
                                </div>
                                <input type="date" v-model="this.filterValues.dateFrom" v-on:keydown.enter="this.GetCompaniesFunction()">
                            </div>

                            <div class="filter" >
                                <div class="textFlex end">
                                    <p class="spec">To</p>
                                </div>
                                <input type="date" placeholder="01/03/2022" v-model="this.filterValues.dateTo" v-on:keydown.enter="this.GetCompaniesFunction()">
                            </div>

                        </div>
                        

                        <div class="addFiltersButton">
                            <button class="generalButton primary" @click="this.GetCompaniesFunction()">Search</button>
                        </div>
                    </div>
                </div>
            </div>

        <TableHeader :paginationValues="this.paginationValues" :entriesTotal="this.companies.total" v-on:OnPaginationChange="Object.assign(this.paginationValues, $event); this.GetCompaniesFunction()"/>
        
        <!-- <div v-if="!this.tableLoader"> -->
            <table class="usersTable" v-if="this.companies">
                    <tr>
                        <th>GROUP</th>
                        <th><div @click="this.SwitchFieldSorting(this.sortValues, 'createAt'), this.paginationValues.entriesStart = 0; this.GetCompaniesFunction()" :class="this.sortValues['createAt']"> CREATED AT <i class="fi fi-br-arrow-down"></i></div></th>
                        <th><div @click="this.SwitchFieldSorting(this.sortValues, 'updateAt'), this.paginationValues.entriesStart = 0; this.GetCompaniesFunction()" :class="this.sortValues['updateAt']"> UPDATE AT <i class="fi fi-br-arrow-down"></i></div></th>
                        
                    </tr>

                    <tr v-for="company in this.companies.groups" :key="company">
                        <td>
                            <div class="user">
                                <div class="userImg">
                                    <i class="fi fi-sr-building"></i>
                                </div>
                                <div class="userInfo">
                                    <p class="userName">{{company.name}}</p>
                                </div>
                            </div>
                        </td>
                        
                        <td class="joinedDate">{{this.FormatDate(company.createAt)}}</td>
                        <td class="joinedDate">{{this.FormatDate(company.updateAt)}}</td>
                    </tr>   
                </table>
        <!-- </div>
        <div v-else style="height:400px"><div class="bigLoadingSpinner main center"></div></div> -->
    </div>
    <div v-else class="bigLoadingSpinner main center"></div>

  </div>
</template>

<script>
import {GetGroups, SearchFunction} from '@/helpers/APIconnection'
import { FormatDate,SwitchFieldSorting } from '@/helpers/utils'
import TableHeader  from '@/components/TableHeader'
export default {
    data:function(){
        return{
            companies:{},
            companySelected:{},
            showFiltersContainer:false,
            loader:true,
            searchText:'',
            tableLoader:false,
            paginationValues:  {
                entriesLimit: 100,
                entriesStart: 0,
            },
            sortValues: {createAt: 'desc'},
            filterValues: {},
        }
    },
    components:{ TableHeader },
    created(){
        GetGroups(this.filterValues, this.sortValues, this.paginationValues.entriesStart, this.paginationValues.entriesLimit).then((response) => {
            this.companies=response.data
            this.loader=false
        }).catch((error) => {
                console.log(error.response);
            });
    },

    methods:{

        FormatDate,
        SwitchFieldSorting,

        GetCompaniesFunction:function(){
            this.tableLoader=true,
            this.showFiltersContainer=false
            GetGroups(this.filterValues, this.sortValues, this.paginationValues.entriesStart, this.paginationValues.entriesLimit).then((response) => {
                this.companies=response.data
                this.tableLoader=false
            }).catch((error) => {
                this.tableLoader=false
                console.log(error.response);
            });

            
        },

        SearchAppCompany:function(){
            
            clearTimeout(this.searchTimeOut)

            if(this.searchText==''){
                this.GetCompaniesFunction()
                return}
    
                this.searchTimeOut = setTimeout(() => { 
                
                SearchFunction('groups',this.searchText).then((response) => {
                    
                    this.companies=response.data
                }).catch((error)=> {
                    console.log(error)
                    this.GetCompaniesFunction()
                })

            },500)

        },
    },
    
}
</script>

<style>

</style>