<template>
    <div v-if="!this.selectedRobot">
        <div class="contextBar">
            <p class="currentView">{{this.$route.name}}</p>

            <div class="titleInfoTagsContainer" style="margin-left: 24px" v-if="this.suggestedRobot">
                <div class="titleInfoTags" >
                    {{this.suggestedRobot.total}} suggested robots
                </div>
            </div>
        </div>
        <div class="usersListContainer" v-if="!this.loader">

                    <div class="responseConfigBar">
                    <div class="searchBar">
                        <i class="fi fi-rr-search"></i>
                        <input @input="this.SearchSuggestedRobotFunction()" type="text" spellcheck="false" placeholder="Search suggested robot" v-model="this.searchText" @focus="$event.currentTarget.parentElement.classList.add('focus')" @blur="$event.currentTarget.parentElement.classList.remove('focus')">
                    </div>
                    <div class="filters" >
                        <button class="filtersButton" @click="this.showFiltersContainer = !this.showFiltersContainer">
                            <i class="fi fi-rr-filter"></i>
                            Filters
                        </button>

                        <div class="filtersContainer" v-if='this.showFiltersContainer'>
                            <p class='filtersTitle'>Filters</p>

                            <div class="filterLine">
                                <div class="filter">
                                    <div class="textFlex">
                                        <p> Created</p>
                                        <p class="spec">From</p>
                                    </div>
                                    <input type="date" v-model="this.filterValues.dateFrom" v-on:keydown.enter="this.GetSuggestedRobotsFunction()">
                                </div>

                                <div class="filter" >
                                    <div class="textFlex end">
                                        <p class="spec">To</p>
                                    </div>
                                    <input type="date" placeholder="01/03/2022" v-model="this.filterValues.dateTo" v-on:keydown.enter="this.GetSuggestedRobotsFunction()">
                                </div>

                            </div>
                            
                            <div class="addFiltersButton">
                                <button class="generalButton primary" @click="this.GetSuggestedRobotsFunction()">Search</button>
                            </div>
                        </div>
                    </div>
                </div>

                <TableHeader :paginationValues="this.paginationValues" :entriesTotal="this.suggestedRobot.total" v-on:OnPaginationChange="Object.assign(this.paginationValues, $event); this.GetSuggestedRobotsFunction()"/>
                <table class="usersTable" v-if="this.suggestedRobot">
                        <tr>
                            <th>USER</th>
                            <th>DESCRIPTION</th>
                            <th><div @click="this.SwitchFieldSorting(this.sortValues, 'createAt'), this.paginationValues.entriesStart = 0; this.GetSuggestedRobotsFunction()" :class="this.sortValues['createAt']"> CREATE DATE <i class="fi fi-br-arrow-down"></i></div></th>
                            <th>TECHONOLOGIES</th>
                        </tr>
                        <tr v-for="suggestion in this.suggestedRobot.items" :key="suggestion" @click="this.selectedRobot=suggestion">
                            <td>
                                <div class="user">
                                    <div class="userInfo">
                                        <p class="userName">{{suggestion.user.name}}</p>
                                        <p class="userEmail" >{{suggestion.user.email}}</p>
                                    </div>
                                </div>
                            </td>
                            <td class="joinedDate limit-size">{{suggestion.description}}</td>
                            <td class="joinedDate">{{FormatDate(suggestion.createAt)}}</td>
                            <td class="joinedDate limit-size">{{suggestion.technologies.toString()}}</td>
                        </tr>
                </table>
        </div>

        <div v-else class="bigLoadingSpinner main center"></div>
    </div>
    <SuggestedRobotView v-else :robotData="this.selectedRobot" v-on:back="this.selectedRobot=undefined"/>
</template>

<script>
import { GetSuggestedRobots, SearchFunction } from '@/helpers/APIconnection'
import TableHeader  from '@/components/TableHeader'
import { FormatDate, SwitchFieldSorting } from '@/helpers/utils'
import SuggestedRobotView from '@/components/SuggestedRobotView'
export default {
    components: { TableHeader,SuggestedRobotView },
    data:function(){
        return{
            suggestedRobot:{},
            loader:true,
            showFiltersContainer:false,
            selectedRobot:undefined,
            searchText:'',
            paginationValues:  {
                entriesLimit: 100,
                entriesStart: 0,
            },
            sortValues: {createAt: 'desc'},
            filterValues: {},
        }
    },
    
    created(){
        GetSuggestedRobots(this.filterValues, this.sortValues, this.paginationValues.entriesStart, this.paginationValues.entriesLimit)
        .then((response) => {
            this.suggestedRobot=response.data
            this.loader=false
            
        }).catch((error) => {
            console.log(error.response)
        })
    },

    methods: {
        FormatDate,
        SwitchFieldSorting,

        GetSuggestedRobotsFunction:function(){
            this.tableLoader=true
            this.showFiltersContainer=false
            GetSuggestedRobots(this.filterValues, this.sortValues, this.paginationValues.entriesStart, this.paginationValues.entriesLimit).then((response) => {       
                this.suggestedRobot=response.data
                this.tableLoader=false
            }).catch((error) => {
                console.log(error)
                this.tableLoader=false
            })

        },

        SearchSuggestedRobotFunction:function(){
            
            clearTimeout(this.searchTimeOut)

            if(this.searchText==''){
                this.GetSuggestedRobotsFunction()
                return}
    
                this.searchTimeOut = setTimeout(() => { 
                
                SearchFunction('suggestedrobots',this.searchText).then((response) => {
                    
                    this.suggestedRobot=response.data
                }).catch((error)=> {
                    console.log(error)
                    this.GetSuggestedRobotsFunction()
                })

            },500)
        }
    },
    
}
</script>

<style>
.limit-size{
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    
}
</style>