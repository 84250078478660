const axios = require('axios')
import store from '../store';

// const baseURL = 'http://localhost:8081/api/admin/'
const baseURL = 'https://managerapi.slang.digital/api/admin/'
//const baseURL = 'http://35.181.55.184/api/admin/'

// =============== USER AUTH ===============
export async function UserSignIn(email, password, utoken){
    let headers = {};
    let data = {}
    let loginType = utoken ? "logintoken" : "login"

    if ( utoken ) headers['utoken'] = utoken
    else data = {email, password};
    return await axios.post(baseURL + "auth/" + loginType + "?customres=true&populate=role", data, { headers })
    .then((response) => {
        store.commit('SetCurrentUser', response.data)
        store.state.isAuthenticated = true
        sessionStorage.setItem('utoken', response.data.token)
        localStorage.setItem('utoken', response.data.token)
        localStorage.setItem('refreshToken', response.data.refreshToken)
        localStorage.setItem('userEmail', response.data.user.email)
        return response
    })
    .catch((error) => { return error.response })
    
}

async function RefreshToken(refreshToken, email){

    const headers = {'clientVersion': store.getters.appVersion};
    const data = {'refreshToken': refreshToken, 'email': email}

    const response = await axios.post(baseURL + "auth/newtoken", data, { headers })
    .catch((error) => { return error.response })

    return response
}

export async function RestoreSession(){
    
    if ( sessionStorage.getItem('utoken') ) {
        const response = await UserSignIn(undefined, undefined, sessionStorage.getItem('utoken')) // >> Login with token

        if ( response && response.status === 200 ) { return true }
    }

    if ( localStorage.getItem('utoken') ) {

        const response = await UserSignIn(undefined, undefined, localStorage.getItem('utoken')) // >> Login with token
        if ( response && response.status === 200 ) { return true }   
        const refreshToken = localStorage.getItem('refreshToken')
        const userEmail = localStorage.getItem('userEmail')

        if ( refreshToken && userEmail ) {

            const refreshResponse = await RefreshToken(refreshToken, userEmail) // >> Refresh token
            if ( refreshResponse && refreshResponse.status === 200 ) {

                const response = await UserSignIn(undefined, undefined, refreshResponse.data.token)
                if ( refreshResponse && response.status === 200 ) { return true }
            }
        }
    }

    return false
}

export async function Logout(){ // Logout
    
    sessionStorage.removeItem('utoken')
    localStorage.removeItem('utoken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('userEmail')

    if ( !sessionStorage.getItem('utoken') ) { return }

    const headers = {'utoken': sessionStorage.getItem('utoken'), 'clientVersion': store.getters.appVersion}

    await axios.post(baseURL + "auth/logout", {}, { headers })

    // Boolean de autenticación
    store.state.isAuthenticated = false
}

function BuildQueries(filters, sortValues, start, limit,populate,filter){ // Construcción de queries

    let queries = []

    if ( sortValues && Object.keys(sortValues).length > 0 ) { // Ordenación por campos

        queries.push( Object.entries(sortValues).map((sort) => { return 'sortby=' + sort[0] + '&sortdir=' + sort[1] } ).join('&') )
    }

    if ( filters && Object.keys(filters).length > 0 ) { // Filtros
        
        queries.push( Object.entries(filters).filter(filter => filter[1]).map((item) => { return item[0] + '=' + item[1] } ).join('&') )

    }

    if ( start ) queries.push('start=' + start)
    if ( limit ) queries.push('limit=' + limit) // Limite de entradas
    if ( populate ) queries.push('populate=' + populate) 
    if ( filter ) queries.push( 'filter=' + filter) 
    queries.push()

    return queries.join('&')
}

// ========================================


export async function GetGroups(filters, sortValues, start, limit){
    
    const headers = {'utoken': sessionStorage.getItem('utoken'), 'clientVersion': store.getters.appVersion}
    let queries = BuildQueries(filters, sortValues, start, limit)
    const response = await axios.get(baseURL+'groups'+'?'+queries, { headers } )
    
    return response
}

export async function GetCategories(){
    
    const headers = {'utoken': sessionStorage.getItem('utoken'), 'clientVersion': store.getters.appVersion}
    
    const response = await axios.get(baseURL+'categories', { headers } )
    
    return response
}

export async function GetTags(){
    
    const headers = {'utoken': sessionStorage.getItem('utoken'), 'clientVersion': store.getters.appVersion}
    
    const response = await axios.get(baseURL+'tags', { headers } )
    
    return response
}


/* ================ SCRIPT ================= */

export async function GetScriptsMetrics(scriptId){
    
    const headers = {'utoken': sessionStorage.getItem('utoken'), 'clientVersion': store.getters.appVersion}
    const response = await axios.get(baseURL+`usagestats/scriptmetrics/?script=${scriptId}`, { headers } )
    
    return response
}

export async function GetScriptsMetricsById(scriptId){
    
    const headers = {'utoken': sessionStorage.getItem('utoken'), 'clientVersion': store.getters.appVersion}
    const response = await axios.get(baseURL+`usagestats/scriptmetrics/${scriptId}`, { headers } )
    
    return response
}

export async function GetScripts(filters, sortValues, start, limit){

    const headers = {'utoken': sessionStorage.getItem('utoken'), 'clientVersion': store.getters.appVersion}
    let queries = BuildQueries(filters, sortValues, start, limit)
    const response = await axios.get(baseURL+'scripts/?populate=tags&populate=categories&populate=scriptVersion&populate=scriptMetrics'+ '&'+ queries, { headers } )

    return response
}

export async function GetFakeCards(filters, sortValues, start, limit){

    const headers = {'utoken': sessionStorage.getItem('utoken'), 'clientVersion': store.getters.appVersion}
    let queries = BuildQueries(filters, sortValues, start, limit)
    const response = await axios.get(baseURL+'scriptrequest?'+ queries, { headers } )

    return response
}

export async function GetTranslations(language = 'en'){
    const headers = {'utoken': sessionStorage.getItem('utoken'), 'clientVersion': store.getters.appVersion}
    const response = await axios.get(baseURL+`internationalization/${language}?field=single`, { headers } )

    return response
}


/* ================ EMAIL ================== */

// export async function GetEmails(){
    
//     const headers = {'utoken': sessionStorage.getItem('utoken'), 'clientVersion': store.getters.appVersion}    
//     const response = await axios.get(baseURL+'emaillogs/?populate=sender&populate=recipients', { headers } )
    
//     return response
// }

// export async function SendEmail(body){

//     const headers = {'utoken': sessionStorage.getItem('utoken'), 'clientVersion': store.getters.appVersion}
//     const response = await axios.post(baseURL+'email/?send=true',body, { headers } )
    
//     return response
// }


/* ================= USER ================== */

// export async function CreateUser(body){
    
//     const headers = {'utoken': sessionStorage.getItem('utoken'), 'clientVersion': store.getters.appVersion}
//     const response = await axios.post(baseURL+'users/',body, { headers } )
    
//     return response
// }

export async function GetUsers(filters, sortValues, start, limit){
    
    const headers = {'utoken': sessionStorage.getItem('utoken'), 'clientVersion': store.getters.appVersion}
    // ----- Queries -----
    let queries = BuildQueries(filters, sortValues, start, limit)
    const response = await axios.get(baseURL+'users?populate=subscription&populate=group'+ '&' + queries, { headers } )
    return response
}


export async function DeleteUser(userId){

    const headers = {'utoken': sessionStorage.getItem('utoken'), 'clientVersion': store.getters.appVersion}
    const response = await axios.delete(baseURL+`users/${userId}`, { headers } )

    return response
}

//=========== SEARCHS ========

export async function SearchFunction(collention,text){
    const headers = {'utoken': sessionStorage.getItem('utoken'), 'clientVersion': store.getters.appVersion}
    const response = await axios.get(baseURL+`search/${collention}/${text}`, { headers } )
    
    return response
}

export function SearchQuery({ collection, sort, start, limit, filter = undefined } = {}) {
    const objectToQueryString = (obj, basePath) => {
        return Object.entries(obj).filter(elem => Object.hasOwn(arguments[0], elem[0])).map(([ k, v ]) => {
            const path = (basePath ? basePath + '.' : '') + encodeURIComponent(k);
            return v instanceof Object ? objectToQueryString(v, path) : path + '=' + encodeURIComponent(v);
        }).join('&');
    };

    if (typeof sort === 'object') {
        sort = Object.entries(sort).map(([ k, v ]) => ([ 'desc', -1, false, '-', 0 ].includes(v) ? '-' : '+') + k).join(' ');
    }

    if (filter === undefined) {
        filter = '';
        const extendFilter = function(template, ...args) {
            if (filter !== '') {
                filter += '&';
            }
            for (let i = 0; i < args.length; i++) {
                filter += template.raw[i];
                if (args[i] instanceof Date) {
                    filter += args[i].toISOString();
                } else if (typeof args[i] === 'number') {
                    filter += args[i];
                } else if (args[i] !== undefined) {
                    filter += JSON.stringify(args[i]);
                }
            }
            filter += template.raw[args.length];
            return extendFilter;
        };
        extendFilter.then = cb => SearchQuery({ collection, sort, start, limit, filter }).then(cb);
        return extendFilter;
    }

    const headers = {'utoken': sessionStorage.getItem('utoken'), 'clientVersion': store.getters.appVersion};
    const responsePromise = collection ? axios.get(baseURL+`search?` + objectToQueryString({ filter, collection, sort, start, limit }), { headers }) : Promise.resolve(objectToQueryString({ sort, start, limit, filter }));

    return responsePromise;
}

export async function GetUserMetrics(userId){
    
    const headers = {'utoken': sessionStorage.getItem('utoken'), 'clientVersion': store.getters.appVersion}
    const response = await axios.get(baseURL+`usagestats/usermetrics/?user=${userId}`, { headers } )
    
    return response
}


/* ============== Admin USER =============== */

export async function CreateAdminUser(body){
    
    const headers = {'utoken': sessionStorage.getItem('utoken'), 'clientVersion': store.getters.appVersion}
    const response = await axios.post(baseURL+'userslang/',body, { headers } )
    
    return response
}

export async function GetAdminUsers(filters, sortValues, start, limit){
    
    const headers = {'utoken': sessionStorage.getItem('utoken'), 'clientVersion': store.getters.appVersion}
    let queries = BuildQueries(filters, sortValues, start, limit)
    const response = await axios.get(baseURL+'adminuser?populate=role'+'&'+queries, { headers } )

    return response
}

export async function UpdateSlangUser(id, body){
    
    const headers = {'utoken': sessionStorage.getItem('utoken'), 'clientVersion': store.getters.appVersion}
    const response = await axios.put(baseURL+`userslang/${id}`,body, { headers } )
    
    return response
}


/* ================= ROLES ================== */

export async function CreateRole(body){
    
    const headers = {'utoken': sessionStorage.getItem('utoken'), 'clientVersion': store.getters.appVersion}
    const response = await axios.post(baseURL+'roles/',body, { headers } )
    
    return response
}

export async function GetRoles(){
    
    const headers = {'utoken': sessionStorage.getItem('utoken'), 'clientVersion': store.getters.appVersion}
    const response = await axios.get(baseURL+`roles` ,{ headers } )
    
    return response
}

export async function UpdateRoleById(body){
    
    const headers = {'utoken': sessionStorage.getItem('utoken'), 'clientVersion': store.getters.appVersion}
    const response = await axios.put(baseURL+'roles/',body, { headers } )
    
    return response
}


/*  =============== TICKETS ===============*/

export async function GetTickets(filters, sortValues, start, limit){
    const headers = {'utoken': sessionStorage.getItem('utoken'), 'clientVersion': store.getters.appVersion}
    let queries = BuildQueries(filters, sortValues, start, limit)
    const response = await axios.get(baseURL+'tickets/?populate=replies&populate=user' + '&' + queries, { headers } ) 
    return response
}

export async function GetUserTicketByID(id){ //Obtener tickets de usuario sortBy: ordenar por ,orderDir ordenar ascendete o descendente
     
    const headers = {'utoken': sessionStorage.getItem('utoken'), 'clientVersion': store.getters.appVersion}
    const response =  await axios.get(baseURL + `tickets/${id}/?populate=user&populate=replies`, { headers })
    
    return response   
}

export async function SendResponseToTicket(id,body){ 
     
    const headers = {'utoken': sessionStorage.getItem('utoken'), 'clientVersion': store.getters.appVersion}
    const response =  await axios.post(baseURL + `tickets/reply/${id}`, body,{ headers })
    
    return response   
}
export async function CloseTicket(id){ 
     
    const headers = {'utoken': sessionStorage.getItem('utoken'), 'clientVersion': store.getters.appVersion}
    const response =  await axios.put(baseURL + `tickets/close/${id}`,{},{ headers })
    
    return response   
}

export async function GetSuggestedRobots(filters, sortValues, start, limit){
    const headers = {'utoken': sessionStorage.getItem('utoken'), 'clientVersion': store.getters.appVersion}
    let queries = BuildQueries(filters, sortValues, start, limit)
    const response = await axios.get(baseURL+'suggest-robot/?populate=user'+ '&' + queries, { headers } ) 
    return response
}
/*  =============== Error Logs ===============*/

export async function GetError(filters, sortValues, start, limit,filter,populate="user"){
    
    const headers = {'utoken': sessionStorage.getItem('utoken'), 'clientVersion': store.getters.appVersion}
    let queries = BuildQueries(filters, sortValues,start, limit,populate,filter)
    console.log(queries)
    const response = await axios.get(baseURL+'errorlogs/?' + queries, { headers } ) 
    return response
}

export async function GetErrorRoboteka(filters, sortValues, start, limit,populate="user"){
    
    const headers = {'utoken': sessionStorage.getItem('utoken'), 'clientVersion': store.getters.appVersion}
    let queries = BuildQueries(filters, sortValues,start, limit,populate)
    console.log(queries)
    const response = await axios.get(baseURL+'errorlogs/?' + queries, { headers } ) 
    return response
}
export async function GetBrowser_flows_error(filters, sortValues, start, limit,populate="user"){
    
    const headers = {'utoken': sessionStorage.getItem('utoken'), 'clientVersion': store.getters.appVersion}
    let queries = BuildQueries(filters, sortValues,start, limit,populate)
    console.log(queries)
    const response = await axios.get(baseURL+'browserflows/flowlog/?' + queries, { headers } ) 
    return response
}
export async function GetMacroLogs(filters, sortValues, start, limit){
    
    const headers = {'utoken': sessionStorage.getItem('utoken'), 'clientVersion': store.getters.appVersion}
    let queries = BuildQueries(filters, sortValues,start, limit)
    console.log(queries)
    const response = await axios.get(baseURL+'macrolog/?' + queries, { headers } ) 
    return response
}
export async function GetBrowserFlows(filters, sortValues, start, limit) {
    const headers = {'utoken': sessionStorage.getItem('utoken'), 'clientVersion': store.getters.appVersion}
    let queries = BuildQueries(filters, sortValues, start, limit)
    const response = await axios.get(baseURL+'browserflows/flow?' + queries, { headers } )
    return response
}

export async function GetBrowserFlowsReport(query) {
    const headers = {'utoken': sessionStorage.getItem('utoken'), 'clientVersion': store.getters.appVersion}
    const response = await axios.get(baseURL+'browserflows/flowlog/reportfile' + (query ? '?' + query : ''), { headers } )
    return response
}

export async function GetFlowManagementFlows() {
    const headers = { utoken: sessionStorage.getItem('utoken'), clientVersion: store.getters.appVersion }
    const response = await axios.get(baseURL+'flow-management/flows', { headers })
    return response
}

export async function GetFlowManagementFlowGroups() {
    const headers = { utoken: sessionStorage.getItem('utoken'), clientVersion: store.getters.appVersion }
    const response = await axios.get(baseURL+'flow-management/flowgroups', { headers })
    return response
}

export async function UpdateFlowManagementFlow(target, data) {
    const headers = { utoken: sessionStorage.getItem('utoken'), clientVersion: store.getters.appVersion }
    const response = await axios.post(baseURL+`flow-management/flow/${target}`, data, { headers })
    return response
}

export async function BatchUpdateFlowManagementFlow(batch) {
    const headers = { utoken: sessionStorage.getItem('utoken'), clientVersion: store.getters.appVersion }
    const response = await axios.post(baseURL+'flow-management/batch/flow', batch, { headers })
    return response
}

/*  =============== Differently managed ===============*/
export function DiMaFetchTransformer(path) {
    return function (url, config = {}) {
        const headers = { utoken: sessionStorage.getItem('utoken'), clientVersion: store.getters.appVersion };
        const fullConfig = Object.assign({}, config, { headers: Object.assign({}, config.headers, headers) });
        return fetch(baseURL + path + (url.match(/^\.($|[/?])/) ? url.substring(1) : url), fullConfig);
    }
}

export function TransformDiMaFetchs(text, path) {
    const fetchWithAuth = `((url, config = {}) => {
        const headers = ${JSON.stringify({'utoken': sessionStorage.getItem('utoken'), 'clientVersion': store.getters.appVersion})};
        Object.assign(config.headers ??= {}, headers);
        return fetch(url, config);
    })`;
    return text.replaceAll(/fetch\((["'])\.\//g, `${fetchWithAuth}($1${baseURL}${path}/`);
}