<template>
    <div v-if="this.errorSelected == undefined">
        <div class="contextBar">
            <p class="currentView">{{ this.$route.name }}</p>

            <!--Para que aparezca cuantos errores totales (Objetos)-->
            <div class="titleInfoTagsContainer" style="margin-left: 24px" v-if="this.errorlogs">
                <div class="titleInfoTags">
                    {{ this.errorlogs.total }} errors
                </div>
            </div>
        </div>
        <div class="usersListContainer" v-if="!this.loader">

            <div class="responseConfigBar">
                <div class="searchBar">
                        <i class="fi fi-rr-search"></i>
                        <input type="text" spellcheck="false" placeholder="Search error or user" v-model="this.searchText"
                        @input="this.SearchAppError()" @focus="$event.currentTarget.parentElement.classList.add('focus')"
                        @blur="$event.currentTarget.parentElement.classList.remove('focus')">
                    </div>
                <div class="filters">
                    <button class="filtersButton" @click="this.showFiltersContainer = !this.showFiltersContainer">
                        <i class="fi fi-rr-filter"></i>
                        Filters
                    </button>

                    <div class="filtersContainer" v-if='this.showFiltersContainer'>
                        <p class='filtersTitle'>Filters</p>

                        <div class="filterLine">
                            <div class="filter">
                                <div class="textFlex">
                                    <p> Created</p>
                                    <p class="spec">From</p>
                                </div>
                                <input type="date" v-model="this.filterValues.dateFrom"
                                    v-on:keydown.enter="this.GetErrorlogsFunction()">
                            </div>

                            <div class="filter">
                                <div class="textFlex end">
                                    <p class="spec">To</p>
                                </div>
                                <input type="date" placeholder="01/03/2022" v-model="this.filterValues.dateTo"
                                    v-on:keydown.enter="this.GetErrorlogsFunction()">
                            </div>

                        </div>

                        <div class="addFiltersButton">
                            <button class="generalButton primary" @click="this.GetErrorlogsFunction()">Search</button>
                        </div>
                    </div>
                </div>
            </div>
            <TableHeader :paginationValues="this.paginationValues" :entriesTotal="this.errorlogs.total"
                v-on:OnPaginationChange="Object.assign(this.paginationValues, $event); this, GetErrorlogsFunction()" />
            <!-- <div v-if="!this.tableLoader"> -->
            <table class="usersTable" v-if="this.errorlogs">
                <tr>
                    <th @click="$event.stopPropagation()">
                        <div class="checkbox" :class="{ checked: this.listItemSelected.length > 0 }"
                            @click="this.ToogleAllCheckBox()">
                            <i class="fi fi-br-check"></i>
                        </div>
                    </th>
                    <th>USER</th>
                    <th>EXCEPTION</th>

                    <th>ERROR</th>
                    <th><div @click="this.SwitchFieldSorting(this.sortValues, 'createAt'), this.paginationValues.entriesStart = 0; this.GetErrorlogsFunction()" :class="this.sortValues['createAt']"> CREATED AT <i class="fi fi-br-arrow-down"></i></div></th>
                </tr>

                <tr v-for="errorlog in this.errorlogs.items" :key="errorlog" @click="this.$saveCurrentScroll(this); this.errorSelected = errorlog">
                    <td @click="$event.stopPropagation()">
                        <div class="checkbox" :class="{ checked: this.listItemSelected.includes(errorlog) }"
                            @click="this.ToggleCheckBox(errorlog); $event.stopPropagation();">
                            <i class="fi fi-br-check"></i>
                        </div>
                    </td>
                    <td>
                        <div class="user">
                            <div class="userInfo">
                                <p class="userName">{{ errorlog.user?.name?? " "}}</p>
                            </div>
                        </div>
                    </td>
                    <td class="joinedDate">{{ errorlog.responseCode }}</td>
                    <td class="joinedDate">
                       
                        <div v-if="typeof errorlog.source === 'string'">{{ errorlog.source }}</div>
                    </td>
                    <td class="joinedDate">{{ FormatDate(errorlog.createAt, errorlog.createAt) }}</td>
                </tr>
            </table>

        </div>
        <div v-else class="bigLoadingSpinner main center"></div>



    </div>
    <ErrorLogsView v-else :errorlogData="this.errorSelected" v-on:back="errorSelected = undefined; this.$backToPreviousScroll(this)" />
</template>
  
<script>
import { GetError, SearchFunction } from '@/helpers/APIconnection'
import { FormatDate, SwitchFieldSorting } from '@/helpers/utils'
import ErrorLogsView from '@/components/ErrorLogsView'
import TableHeader from '@/components/TableHeader'
export default {

    components: { ErrorLogsView, TableHeader },

    data: function () {
        return {
            errorlogs: {},
            errorSelected: undefined,
            listItemSelected: [],
            searchText: '',
            loader: true,
            showFiltersContainer: false,
            tableLoader: false,
            paginationValues: {
                entriesLimit: 100,
                entriesStart: 0,
            },
            sortValues: { createAt: 'desc' },
            filterValues: {},
            filter:'roboteka'
        }
    },

    created() {

        GetError(this.filterValues, this.sortValues, this.paginationValues.entriesStart, this.paginationValues.entriesLimit,this.filter).then((response) => {
            this.errorlogs = response.data
            this.loader = false
            console.log(this.errorlogs)
        }).catch((error) => {
            console.log(error.response)
        })
    },

    methods: {
        FormatDate,
        SwitchFieldSorting,

        GetErrorlogsFunction: function () {
            this.tableLoader = true
            this.showFiltersContainer = false
            GetError(this.filterValues, this.sortValues, this.paginationValues.entriesStart, this.paginationValues.entriesLimit).then((response) => {
                this.errorlogs = response.data
                this.tableLoader = false
            }).catch((error) => {
                console.log(error)
                this.tableLoader = false
            })

        },

        SearchAppError: function () {

            clearTimeout(this.searchTimeOut)

            if (this.searchText == '') {
                this.GetErrorlogsFunction()
                return
            }

            this.searchTimeOut = setTimeout(() => {

                SearchFunction('errorlogs', this.searchText).then((response) => {

                    this.errorlogs = response.data
                }).catch((error) => {
                    console.log(error)
                    this.GetErrorlogsFunction()
                })

            }, 500)

        },
        ToggleCheckBox: function (object) {
            var indexOf = this.listItemSelected.indexOf(object);
            if (indexOf > -1) {
                this.listItemSelected.splice(indexOf, 1);
            } else {
                this.listItemSelected.push(object);
            }
        },

        ToogleAllCheckBox: function () {
            if (this.listItemSelected.length > 0) {
                this.listItemSelected = [];
            } else {
                this.listItemSelected = [...this.users.users];
            }
        },


    },

};
</script>
      
      