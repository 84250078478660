<template>
    <div class="contextBar">
        <div class="backButton" @click="this.$emit('back')">
            <i class="fi fi-sr-angle-left"></i>
        </div>
        <p class="currentView">Error Log</p>
    </div>

    <div class="suggested-robot">
        <div class="user-info">
            <p class="title">Error Log Info</p>
            <p>Type Exception: {{this.errorlogData.responseCode}}</p>
            <p>Description: {{this.errorlogData.source}}</p>
            <p>Date: {{ FormatDate(errorlogData.createAt, errorlogData.createAt)}}</p>
            <p v-if="this.errorlogData.errorObject != undefined">Info: {{ errorlogData.errorObject}}</p>
            <p v-if="this.errorlogData.other != undefined">Info: {{ errorlogData.other}}</p>
            
         

           
        </div>
        <div class="user-info" v-if="this.errorlogData.user != undefined" >
            <p class="title">User Info</p>
            <p>Name: {{this.errorlogData.user.name}}</p>
            <p>Email: {{this.errorlogData.user.email}}</p>
            <p>Phone: {{this.errorlogData.user.phone}}</p>
            <p>CompanyName: {{this.errorlogData.user.companyName}}</p>
            <p>CompanyRole: {{this.errorlogData.user.companyRole}}</p>
            


        </div>
    </div>

</template>

<script>
import { FormatDate } from '@/helpers/utils'
export default {
    props: [ 'errorlogData' ],

    data:function(){
        return{

        }
    },
    methods: {
        FormatDate}
    
}


</script>
<style>

.suggested-robot{
    display:flex;
    flex-direction: column;
    gap:8px;
    padding: 24px;
    margin: 24px;
    border-radius: 4px;
    background: white;
    

}
.user-info{
    display: flex;
    flex-direction: column;
    gap:4px;
    user-select: text;
}
.user-info .title{
    font-weight: bold;
    font-size: 20px;

}
</style>