// ==================== HUMAN FILE SIZE ====================

export function HumanFileSize(bytes) {
    if ( !bytes ) return
    
    const thresh = 1000; // Base 1000, sistema internacional

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
    let u = -1;
    const r = 100; // Obtener dos decimales

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(2) + ' ' + units[u];
}

// ==================== DATE FORMAT ====================

export function FormatDate(date, time = false) {

    const dateObj = new Date(date)
    return `${dateObj.getDate().toString().padStart(2, 0)}/${(dateObj.getMonth() + 1).toString().padStart(2, 0)}/${dateObj.getFullYear()}` + (time ? ` - ${dateObj.getHours().toString().padStart(2, 0)}:${dateObj.getMinutes().toString().padStart(2, 0)}` : '')
}

// ==================== CURRENCY FORMAT ====================

const currencyFormater = new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR', });

export function CurrencyNumberFormat(value){ return currencyFormater.format(value) }


// ==================== EMAIL FORMAT ====================

export function EmailValidator(email) {

    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
    return regex.test(String(email).toLowerCase())
}

export function GetTaskImg(img/*, id*/){

    if ( img ) {
        try { return require('@/assets/scriptImages/' + img + '.png') }
        catch (error) { return require("@/assets/scriptImages/scriptNotFound.svg") }
    }
    else { return require("@/assets/scriptImages/scriptNotFound.svg") }

    // To Do - Si la imagen no existe, la descarga desde la base de datos, se podría referenciar con una URL  ||  >> GetScriptImg()
}

// ==================== Sorting Field ====================

export function SwitchFieldSorting(sortValues, key){

    if ( !sortValues[key] ) {

        // [ TEMPORAL ] - Mientras el backend no soporte ordenación multiple.
        // Forma de eliminar todos los datos del sortValues, no se puede hacer con: sortValues = {} ya que se estaría remplazando la referencia del objeto.
        Object.keys(sortValues).forEach((key) => { delete sortValues[key] })
        sortValues[key] = 'desc'

    } else if ( sortValues[key] === 'desc' ) {

        sortValues[key] = 'asc'
    
    } else {
        delete sortValues[key];
        sortValues['createAt'] = 'desc'
    }
}

export function UserSubscriptionType(subscription){


        // Suscripción cancelada sin tiempo restante
        if ( subscription.stripeSubStatus === 'canceled' ) { return 'basic' }

        // Suscripción cancelada con tiempo restante mayor o igual a ahora mismo
        else if ( subscription.stripeSubStatus === 'active' && subscription.cancelAt && ( new Date(subscription.cancelAt * 1000) >= new Date() ) ) { return 'pro_canceled' }

        // Suscripción activa
        else if ( subscription.stripeSubStatus === 'active' ) { return 'pro' }

        // Suscripción de prueba + suscripción
        else if ( subscription.stripeSubStatus === 'trialing' && subscription.upcomingInvoice ) { return 'pro' }

        // Suscripción de prueba
        else if ( subscription.stripeSubStatus === 'trialing' && !subscription.upcomingInvoice ) { return 'trialing' }

        else { return 'basic' }

}

export function Download(data) {

    if ( !data.url ) {

        if ( data.blob ) { data.data = data.blob }
        if ( data.arraybuffer ) { /* Conversion */ }

        data.url = URL.createObjectURL(data.data)
    }

    if ( !data.filename ) { data.filename = data.url.split('/').reverse()[0] } // Build filename from url
    if ( data.extension ) { data.filename += data.extension } // Add extension to filename

    const a = document.createElement('a')
    a.setAttribute('href', data.url)
    a.setAttribute('download', data.filename)
    document.body.appendChild(a)
    a.click()
    a.parentNode.removeChild(a)

    URL.revokeObjectURL(data.url)
}

