<template>
  <TopMenu v-if="this.$route.name !== 'Login'"/>
  <router-view/>
  <FloatingMessages/>
  <!-- <SendEmail v-if="this.store.getters.sendEmailParameters"/> -->
  
  <button class="upButton" v-if="this.scrollY > 300" @click="this.ScrollToTop()">
    <i class="fi fi-rr-angle-up"></i>
  </button>
</template>

<script>
import TopMenu from '@/components/TopMenu'
import FloatingMessages from '@/components/FloatingMessages'
// import SendEmail from '@/components/SendEmail'

import store from './store'
import { RestoreSession } from '@/helpers/APIconnection'
import { InitializeDefaultTranslation } from './i18n'

export default {

  components: { TopMenu, FloatingMessages },

  data: function() {
    return {
      store,
      scrollY: 0,
    }
  },

  async created() {

    // >> Si no hay datos pero hay un token de sesión se autentifica con ese token.
    InitializeDefaultTranslation()

    this.$router.beforeEach( async (to) => {

      if ( to.name !== 'Login' ) { // >> Si la ruta no cuenta con ninguna comprobación, vas directo.

        if ( !store.state.isAuthenticated ) { // Si el usuario no está autenticado...

          const response = await RestoreSession()
          if ( response ) { if ( to.path === '/' ) { this.$router.replace('users') } }
          else { this.$router.replace('login') }

        }
      }
    })
  },

  mounted() {

    window.addEventListener('scroll', this.HandleScroll);
  },

  computed:{

    getAppVersion() {
      return store.state.appVersion
    },

    CurrentUser(){
      return store.getters.currentUser
    },

    currentRouteName() {
      return this.$route.name;
    },
  },

  methods: {

    HandleScroll: function() {
      this.scrollY = document.documentElement.scrollTop
    },

    ScrollToTop: function() { window.scrollTo({ top: 0, behavior: "smooth" }) }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
@import url('https://cdn-uicons.flaticon.com/uicons-regular-rounded/css/uicons-regular-rounded.css');
@import url('https://cdn-uicons.flaticon.com/uicons-solid-rounded/css/uicons-solid-rounded.css');
@import url('https://cdn-uicons.flaticon.com/uicons-bold-rounded/css/uicons-bold-rounded.css');


:root {
  --main-100: #011A58;
  --main-75: #415382;
  --main-50: #A0A9C0;
  --main-10: #ECEEF3;
  --main-5: #F2F4F7;

  --second-100: #219EBC;
  --second-75: #59B6CD;
  --second-50: #90CFDD;
  --second-10: #C7E7EE;
  --second-5: #F4FAFC;

  --cta-100: #D0098D;
  --cta-75: #DC47A9;
  --cta-50: #E784C6;
  --cta-10: #FAE6F4;
  --cta-5: #FDF3F9;

  --cta2-100: #FFB703;
  --cta2-75: #FFDB81;
  --cta2-50: #FFEDC0;
  --cta2-10: #FFF8E6;
  --cta2-5: #FFFBF2;

   --gradient-h-c-s: linear-gradient(135deg, #011A58 40%, #D0098D 100%);
}

html, body {
  overflow: hidden;
  height: 100%;
}

#app {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

#app::-webkit-scrollbar {
  width: 12px;
}

/* Track */
#app::-webkit-scrollbar-track {
  background: var(--main-10);
  border-radius: 4px;
}
 
/* Handle */
#app::-webkit-scrollbar-thumb {
  background: var(--main-50);
  border-radius: 1px;
}

#app, body {
  box-sizing: border-box;
  font-family: 'IBM Plex Sans';
  user-select: none;
  color: var(--main-100);
  background: var(--main-5);
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

i {
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes fade {
  from { opacity: 0 }
  top { opacity: 1 }
}

@keyframes modalAnim {
  0% { opacity: 0; transform: scale(0.8); }
  100% { opacity: 1; transform: scale(1); }
}

@keyframes swipeDown {
  from { opacity: 0; transform: translateY(-60px); }
  top { opacity: 1; transform: translateY(0); }
}

@keyframes swipeUp {
  from {
    transform: translateY(10%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.globalOverlay {
  position: fixed;
  inset: 0;
  backdrop-filter: blur(15px);
  background: rgba(1, 26, 88, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;

  animation: fade 0.15s;
}

.upButton {
  position: fixed;
  height: 36px;
  width: 36px;
  background: var(--second-100);
  bottom: 8px;
  right: 8px;
  color: #FFF;
  border-radius: 50%;
  box-shadow: 1px 1px 5px var(--main-50);
  cursor: pointer;

  animation: fade 0.2s;
}

/* ---------- LOADERS ---------- */

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.smallLoadingSpinner {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 4px solid transparent;

  animation: rotate 0.8s linear infinite;
}

.tinyLoadingSpinner {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 3px solid transparent;

  animation: rotate 0.8s linear infinite;
}

.bigLoadingSpinner {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  border: 8px solid transparent;

  animation: rotate 0.7s linear infinite;
}

.tinyLoadingSpinner.center {
  position: absolute;
  top: calc(50% - 7.5px);
  right: 8px;
}

.bigLoadingSpinner.center {
  position: absolute;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
}

.bigLoadingSpinner.main, .smallLoadingSpinner.main, .tinyLoadingSpinner.main {
  border-top-color: var(--main-100);
  border-right-color: var(--main-100);
}

.bigLoadingSpinner.wg, .smallLoadingSpinner.wg, .tinyLoadingSpinner.wg {
  border-top-color: #FFF;
  border-right-color: #FFF;
}

.bigLoadingSpinner.cta2, .smallLoadingSpinner.cta2, .tinyLoadingSpinner.cta2 {
  border-top-color: var(--cta-100);
  border-right-color: var(--cta-100);
}


/*----- CONTEXT BAR -----*/
.contextBar {
  
  display: flex;
  align-items: center;
  padding: 12px 40px;
  box-shadow: 0 2px 5px var(--main-5);
  background: #FFF;
}

.contextBar .currentView {
  font-size: 24px;
  font-weight: 700;
}

.contextBar .contextBarButtonsContainer{
    
    display: flex;
    gap: 12px;
    justify-content: flex-end;

}
/*----- TITLE INFO TAGS -----*/
.titleInfoTagsContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.titleInfoTagsContainer .titleInfoTags {
  height: 28px;
  display: flex;
  align-items: center;
  background: var(--main-5);
  color: var(--main-75);
  border-radius: 4px;
  padding: 0 12px;
  font-size: 12px;
  font-weight: 700;
}
/* ---------- BUTTONS ---------- */
.generalButton {
  position: relative;
  height: 40px;
  border: none;
  outline: none;
  border-radius: 4px;
  padding: 0 24px;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.generalButton svg {
  height: 12px;
}

.generalTextButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  white-space: nowrap;
  background: transparent;
  border: none;
  outline: none;
  color: #FFF;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
}

.generalTextButton:disabled {
  opacity: 0.3;
  pointer-events: none;
}

.generalTextButton svg {
  height: 12px;
  width: 12px;
}
.generalTextButton svg path {
  stroke: #FFF;
  stroke-width: 2;
}

.generalTextButton:hover {
  text-decoration: underline;
}

.generalButton.primary {
  color: #FFF;
  background: var(--cta-75);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);

  transition: background 0.2s, color 0.2s;
}

.generalButton.secondary {
  background: none;
  border: 1px solid var(--cta-75);
  color: var(--cta-75);
  padding: 0 20px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);

  transition: box-shadow 0.3s, color 0.2s;
}

.generalButton.secondary svg path {
  stroke: var(--cta-75);
  stroke-width: 2;
}

.generalButton.primary:disabled {
  background: var(--cta-50);
  pointer-events: none;
}

.generalButton.secondary:disabled {
  opacity: 0.4;
  pointer-events: none;
}

.generalButton.primary:hover {
  background: var(--cta-100);
}

.generalButton.secondary:hover {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  color: var(--cta-100);
  border-color: var(--cta-100);
}
.generalButton.secondary:hover svg path {
  stroke: var(--cta-100);
}

.generalButton.primary:active {
  background: var(--cta-100);
}

.generalModal {
  min-height: 150px;
  min-width: 400px;
  background: #FFF;
  box-shadow: 0 0 5px 1px var(--main-50);
  border-radius: 8px;

  animation: swipeDown 0.5s;
}

.generalModal .generalModalHeader {
  min-height: 50px;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid var(--main-10);
  font-size: 18px;
  font-weight: 700;
  color: var(--main-100);
}

.generalModal .generalModalFooter {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
  border-top: 1px solid var(--main-10);
}

.generalModal .generalModalContent {
  padding: 12px 16px;
}

</style>
