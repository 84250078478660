<template>
    <div class="navigationMenu">
        <img src="@/assets/slang/slang_logo.png" alt="" style="height: 36px; margin-right: 32px">
        <div class="navigationItem" v-for="route in this.routes" :key="route" :class="{'active' : route.name === this.$route.name}" @mouseenter="this.mouseposition=route" @mouseleave="this.mouseposition=undefined" @click="route.path && this.$router.replace(route.path)" >
            <span>{{route.name}}</span>
            <div class="submenu" v-if="route.subRoutes?.length && this.mouseposition===route">
                <div v-for="subroute in route.subRoutes" :key="subroute" class="submenu-item" @click.stop="this.$router.replace(subroute.path)" :class="{'active' : subroute.name === this.$route.name}">{{subroute.name}}</div>
            </div>
        </div>
    </div>

    <div class="navigationDivider"></div>
</template>

<script>
export default {
    data() {
        return {
            mouseposition:undefined,
            routes: [
                /*{
                    name: 'Dashboard',
                    path: 'dashboard'
                },*/
                {
                    name: 'Users',
                    path: 'users'
                },
                {
                    name: 'Scripts',
                    path: 'scripts',
                    subRoutes: [
                        {
                            name: 'Scripts',
                            path: 'scripts'
                        },
                        {
                            name:'Suggested Robots',
                            path:'suggestedRobots'
                        },
                        {
                            name:'Fake Cards',
                            path:'fakecards'
                        },
                    ]
                },
                {
                    name: 'Groups',
                    path: 'groups'
                },
                // {
                //     name: 'Emails',
                //     path: 'emails'
                // },
                {
                    name: 'Tickets',
                    path: 'tickets'
                },

                {
                    name: 'Admin Users',
                    path: 'adminusers'
                },
                {
                    name: 'Error Logs',
                    path: 'errorlogs',
                    subRoutes: [
                        {
                            name: 'Error Logs',
                            path: 'errorlogs'
                        },
                        {
                            name:'Roboteka',
                            path:'roboteka'
                        },
                        {
                            name:'Task Recorder',
                            path:'taskRecorder'
                        },
                        {
                            name:'Flow Logs',
                            path:'flowlogs'
                        },
                    ]
                },
                {
                    name: 'Flows',
                    path: 'browserflow-stats',
                    subRoutes: [
                        {
                            name: 'Browser flow stats',
                            path: 'browserflow-stats'
                        },
                        {
                            name: 'Management',
                            path: 'flows-management'
                        },
                    ]
                },
                {
                    name: 'Edit (DEV) ...',
                    subRoutes: [
                        {
                            name: 'Browser flows',
                            path: 'browserflows'
                        },
                        {
                            name: 'Flows compiler',
                            path: 'flows-compiler'
                        },
                    ]
                },
            ],
        }
    }
}
</script>

<style>

.navigationDivider {
    width: 100%;
    height: 1px;
    background: var(--main-10);
}

.navigationMenu {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 24px;
    background: #FFF;
    background: #FFF;
}

.navigationMenu .navigationItem {
    position:relative;
    padding: 12px 24px;
    font-size: 18px;
    font-weight: 500;
    color: var(--main-75);
    border-radius: 8px;
    cursor: pointer;
}

.navigationMenu .navigationItem:hover {
    background: var(--main-5);
}

.navigationMenu .navigationItem.active {
    background: var(--main-5);
    color: var(--second-100);
}

.submenu{
    display: flex;
    gap:4px;
    background: #FFF;
    position:absolute;
    left:0;
    bottom:-50px;
    padding: 8px;
    border-radius: 8px;
    border:1px solid var(--second-100);
    color: var(--main-75);
    font-size: 16px;
    z-index: 1;
}

.submenu .submenu-item{
    padding: 8px;
    border-radius: 8px;
    white-space: nowrap;
}
.submenu .submenu-item.active {
    background: var(--main-5);
    color: var(--second-100);
}
.submenu-item:hover{
    background: var(--main-5);
    cursor: pointer;
}

</style>