<template>
    
    <div v-if="this.CurrentUser.user.role.name!=='Admin'">
        Only Admin Users can enter here
    </div>
    <div v-else>
        <div v-if="!this.selectedSlangUser">
            <div class="contextBar" style="justify-content: space-between">
                <div style="display: flex">
                    <p class="currentView">{{ this.$route.name }}</p>
                </div>

                <div class="contextBarButtonsContainer">
                    <button v-if="this.listItemSelected.length > 0" class="generalButton secondary" @click="this.SendEmail()">
                        Delete Users
                    </button>
                    
                    <button class="generalButton primary" @click="this.selectedSlangUser = {}">
                        New User
                    </button>
                </div>
            </div>
            <div class="usersListContainer" v-if="!this.loader">
                <div class="responseConfigBar">
                    <div class="searchBar">
                        <i class="fi fi-rr-search"></i>
                        <input type="text" spellcheck="false" v-model="this.searchText" placeholder="Search user"
                            @input="this.SearchAppAdminUser()"
                            @focus="$event.currentTarget.parentElement.classList.add('focus')"
                            @blur="$event.currentTarget.parentElement.classList.remove('focus')" />
                    </div>
                    <div class="filters" >
                    <button class="filtersButton" @click="this.showFiltersContainer = !this.showFiltersContainer">
                        <i class="fi fi-rr-filter"></i>
                        Filters
                    </button>

                    <div class="filtersContainer" v-if='this.showFiltersContainer'>
                        <p class='filtersTitle'>Filters</p>

                        <div class="filterLine">
                            <div class="filter" v-if="this.roles">
                               
                                <p>Role</p>
                                <select @change="$event.currentTarget.value !='ALL' ? this.filterValues.role=$event.currentTarget.value : this.filterValues = {}"> 
                                    <option>ALL</option>
                                    <option v-for="rol in this.roles" :key="rol" :value="rol._id">{{rol.name}}</option>
                                    
                                </select>
                            </div>
                        </div>
                        
                        <div class="addFiltersButton">
                            <button class="generalButton primary" @click="this.GetAdminUsersFunction()">Search</button>
                        </div>
                    </div>
                </div>
                </div>

                <TableHeader :paginationValues="this.paginationValues" :entriesTotal="this.users.total" v-on:OnPaginationChange="Object.assign(this.paginationValues, $event); this.GetAdminUsersFunction()"/>

                <div v-if="!this.tableloader">
                    <table class="usersTable" v-if="this.users">
                        <tr>
                            <th @click="$event.stopPropagation()">
                                <div class="checkbox" :class="{ checked: this.listItemSelected.length > 0 }"
                                    @click="this.ToogleAllCheckBox()">
                                    <i class="fi fi-br-check"></i>
                                </div>
                            </th>
                            <th>USER</th>
                            <th>EMAIL</th>
                            <th>ROLE</th>
                        </tr>

                        <tr v-for="user in this.users.users ?? this.users.items" :key="user" @click="this.ShowSlangUserView(user)">
                            <td @click="$event.stopPropagation()">
                                <div class="checkbox" :class="{ checked: this.listItemSelected.includes(user) }" @click="this.ToggleCheckBox(user);$event.stopPropagation();">
                                    <i class="fi fi-br-check"></i>
                                </div>
                            </td>
                            <td>
                                <div class="user">
                                    <div class="userImg">
                                        <i class="fi fi-rr-user"></i>
                                    </div>
                                    <div class="userInfo">
                                        <p class="userName">{{ user.name }}</p>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <p>{{ user.email }}</p>
                            </td>
                            <td>
                                <p>{{ user.role ? user.role.name: '-' }}</p>
                            </td>
                        </tr>
                    </table>
                </div>
                <div v-else style="height:400px"><div class="bigLoadingSpinner main center"></div></div>
            </div>
            <div v-else class="bigLoadingSpinner main center"></div>
        </div>
        <AdminUserView v-else :userData="this.selectedSlangUser" v-on:back="this.HideSlangUserView()" />
    </div>
</template>

<script>
import { GetAdminUsers, SearchFunction, GetRoles } from "@/helpers/APIconnection";
import AdminUserView from "@/components/AdminUserView.vue";
import TableHeader  from '@/components/TableHeader'
import store from '@/store'

export default {
    data() {
        return {
            users: undefined,
            loader: true,
            selectedSlangUser: undefined,
            listItemSelected: [],
            showFiltersContainer:false,
            tableloader:false,
            searchText:'',
            roles:undefined,
            paginationValues:  {
                entriesLimit: 100,
                entriesStart: 0,
            },
            sortValues: {createAt: 'desc'},
            filterValues: {},
        };
    },
    created() {
        GetAdminUsers()
            .then((response) => {
                this.users = response.data;
                this.loader = false;
            })
            .catch((error) => {
                console.log(error.response);
            });

            GetRoles().then((response) => {
                this.roles=response.data
            }).catch((error) => {
                console.log(error.response);
            });
    },

    components: { AdminUserView,TableHeader },

    computed:{

        CurrentUser() { return store.getters.currentUser },
    },

    methods: {
       
        GetAdminUsersFunction: function(){
            this.tableloader = true;
            this.showFiltersContainer=false
            GetAdminUsers(this.filterValues, this.sortValues, this.paginationValues.entriesStart, this.paginationValues.entriesLimit).then((response) => {

                this.users = response.data;
                this.tableloader = false;

            }).catch((error) => {
                console.log(error.response);
                this.tableloader = false;
            });

        },

        SearchAppAdminUser:function(){
            
            clearTimeout(this.searchTimeOut)

            if(this.searchText==''){
                this.GetAdminUsersFunction()
                return}
    
                this.searchTimeOut = setTimeout(() => { 
                
                SearchFunction('adminusers',this.searchText).then((response) => {
                    
                    this.users=response.data
                }).catch((error)=> {
                    console.log(error)
                    this.GetAdminUsersFunction()
                })

            },500)

        },

        ShowSlangUserView: function (user) {
            this.selectedSlangUser = user;

            this.$saveCurrentScroll(this)
        },

        HideSlangUserView: function () {
            this.selectedSlangUser = undefined;

            this.$backToPreviousScroll(this)
        },


        ToggleCheckBox: function (object) {
            var indexOf = this.listItemSelected.indexOf(object);
            if (indexOf > -1) {
                this.listItemSelected.splice(indexOf, 1);
            } else {
                this.listItemSelected.push(object);
            }
        },

        ToogleAllCheckBox: function () {
            if (this.listItemSelected.length > 0) {
                this.listItemSelected = [];
            } else {
                this.listItemSelected = [...this.users.users];
            }
        },
    },
};
</script>


<style>
</style>