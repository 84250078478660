<template>
	<div style="all: reset; overflow: auto;">
		<div v-html="html"></div>
	</div>
</template>
<script>
import fileHtml from './BrowserFlows.html'
import { TransformDiMaFetchs } from '@/helpers/APIconnection'

export default {
	data: function() {
		return {
			html: ''
		}
	},
	created() {
		let body = fileHtml.substring(fileHtml.indexOf('<' + 'body>') + 6, fileHtml.indexOf('</' + 'body>'));
		let style = fileHtml.substring(fileHtml.indexOf('<' + 'style>') + 7, fileHtml.indexOf('<' + '/style>'));
		let script = TransformDiMaFetchs(body.substring(body.indexOf('<' + 'script>') + 8, body.indexOf('</' + 'script>')), 'di_ma/browserflows');
		this.html = body + '<' + 'style>' + style + '</' + 'style>';
		this.$nextTick(() => {
			Object.assign(window, new Function('', script + 'return {' + [ ...script.matchAll(/function (\w+)/g) ].map(match => match[1]).join(',') + '};')());
		});
	},
}
</script>