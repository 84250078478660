<template>
    <div class="contextBar">
        <div class="backButton" @click="this.$emit('back')">
            <i class="fi fi-sr-angle-left"></i>
        </div>
        <p class="currentView" v-if="this.user._id">View user details</p>
        <p class="currentView" v-else>Create user</p>

    </div>

    <div class="userViewLayout">

        <div class="userDetails">

            <div class="userDataAndImg">

                <div class="userImg">
                    <i class="fi fi-rr-user"></i>
                </div>

                <div class="userData">

                    <div class="userName">
                        {{this.user.name}}
                        <div class="userTags">
                            <div class="userTag" v-if="this.user.role">{{this.user.role.name}}</div>      
                        </div>
                 
                    </div>

                    <div class="userInfoContainer">
                        <div class="userInfo" @click="this.CopyToClipboard(this.user.email)">
                            <i class="fi fi-sr-envelope"></i>
                            <p class="text">{{this.user.email ? this.user.email: 'Unknowk'}}</p>
                        </div>
                    </div>
                </div>

            </div>

        <!-- Menú del panel de control -->
            <div class="userViewSectionMenu">
                <div class="userViewSection" @click="this.OnMenuElementClick($event.currentTarget, 0)" ref="initialSection" :class="{'selected' : this.currentSection === 0}" v-if="this.user._id">Role</div>
                <div class="userViewSection" @click="this.OnMenuElementClick($event.currentTarget, 1)" ref="createInitialSection" :class="{'selected' : this.currentSection === 1}">Settings</div>
                

                <div class="menuMark" ref="menuMark"/>
            </div>
        </div>

        <div class="userSectionContent" v-if="this.currentSection === 0">

            <div class="sectionPanel">
                <div class="sectionTitle">
                    <p>{{this.user.role.name}}</p>
                    
                </div>

                <div class="permissionsContainer">
                    <div class="permissionsContainerTittle">Permisions</div>
                        <table class="usersTable">
                            <tr>
                                <th>Table</th>
                                <th>Create</th>
                                <th>Read</th>
                                <th>Update</th>
                                <th>Delete</th>
                                <th>List</th>
                            </tr>


                            <tr v-for="permiss in Object.entries(this.user.role.permission)" :key="permiss" >
                                <td>{{permiss[0]}}</td>
                                <td>{{permiss[1].includes('C')}}</td>
                                <td>{{permiss[1].includes('R')}}</td>
                                <td>{{permiss[1].includes('U')}}</td>
                                <td>{{permiss[1].includes('D')}}</td>
                                <td>{{permiss[1].includes('E')}}</td>
                            </tr>


                        </table>
                    </div> 

            </div>
        </div>

        <div class="userSectionContent" v-else-if="this.currentSection === 1">

            <div class="sectionPanel">
            
                <div class="sectionTitle">SETTINGS</div>

                <div class="userInputContainer">

                    <div class="userInput">
                        <p class="label">User Name</p>
                        <input type="text" placeholder="Enter user name" spellcheck="false" v-model="this.user.name">
                    </div>

                    <div class="userInput">
                        <p class="label">Email Address</p>
                        <input type="text" placeholder="Enter user email" spellcheck="false" v-model="this.user.email">
                    </div>

                    <div class="userInput">
                        <p class="label">Password</p>
                        <input type="password" placeholder="Enter user password" spellcheck="false" v-model="this.user.password">
                    </div>

                    <div class="userInput">
                         <p class="label">Role</p>
                         <select >
                            <option v-for="rol in this.roles" :key="rol">{{rol.name}}</option> 
                            </select>


                    </div>
                </div>


                <div class="sectionFooter" style="justify-content: flex-end">
                    <button class="generalButton secondary" @click="this.user = JSON.parse(JSON.stringify(this.initialData))">Reset</button>
                    <button class="generalButton primary" @click="ApplyButton()">Apply</button>
                </div>
            </div>

            <!-- <div class="sectionPanel" v-if="this.user._id">
            
                  <div class="sectionTitle">PERMISIONS SETTINGS</div>

                    <div class="sectionFooter" style="justify-content: flex-end">
                        <button class="generalButton secondary" @click="this.user = JSON.parse(JSON.stringify(this.initialData))">Reset</button>
                        <button class="generalButton primary" @click="ApplyButton2()">Apply</button>
                    </div>

            </div> -->

            <div class="sectionPanel" v-if="this.user._id">
                <div class="sectionTitle">DELETE ACCOUNT</div>

                <div style="display: flex; justify-content: flex-end; padding: 24px">
                    <button class="generalButton primary" @click="this.showDeactivateAccountModal = true">Delete Account</button>
                </div>
            </div>
        </div>

    </div>

   

    <!------ DELETE ACCOUNT MODAL ------>
    <div class="globalOverlay" v-if="this.showDeactivateAccountModal" @click="this.showDeactivateAccountModal = false">
        <div class="generalModal" @click="$event.stopPropagation()">
            <div class="generalModalHeader">
                DEACTIVATE ACCOUNT
                <i class="fi fi-rr-shield-exclamation" style="margin-left: 12px"></i>
            </div>

            <div class="generalModalContent">
                You are about to <b>delete</b> this user
                <br><br>
                Are you sure about that ?
                <br><br>
            </div>

            <div class="generalModalFooter" style="justify-content: flex-end">
                <button class="generalButton secondary">Yes</button>
                <button class="generalButton primary" @click="this.showDeactivateAccountModal = false">No</button>
            </div>
        </div>
    </div>
</template>

<script>
//import {CreateRole, CreateSlangUser, UpdateSlangUser} from '@/helpers/APIconnection'
import store from '@/store'
import {  GetRoles} from '../helpers/APIconnection'

export default {

    props: [ 'userData' ],
    emits: [ 'back' ],

    data: function() {
        return {
            initialData: undefined, // User data backup
            user: {}, // User data
            userMetric:{},
            currentSection: null,
            showDeactivateAccountModal: false,
            showCompanyModal: false,
            roles:undefined
        }
    },

    mounted() {

        this.initialData = {...this.userData}
        this.user = JSON.parse(JSON.stringify(this.initialData))

        GetRoles().then((response) => {
            
            this.roles=response.data
        })
       
        if(this.user._id){
            this.OnMenuElementClick(this.$refs.initialSection)
        }else{
            
            this.OnMenuElementClick(this.$refs.createInitialSection,1)
        }
        
        
    },
    
    methods: {



        OnMenuElementClick: function(menuElement, section){

            this.currentSection = section ?? 0

            if ( !menuElement ) return
            
            this.$refs.menuMark.style.left = menuElement.offsetLeft + 'px'
            this.$refs.menuMark.style.width = menuElement.offsetWidth + 'px'
        },

        CopyToClipboard: function(text) {

            if ( text ) {
                navigator.clipboard.writeText(text)
                store.commit('CreateFloatingMessage', { id: 'textCopied', text: 'Text copied!', time: 2000})
            }
        },

        ApplyButton: function(){
           

        },
  
    }
}
</script>

<style>
.permissionsContainer{
    padding: 16px;
}
.permissionsContainerTittle{
    font-weight: 600;
}



</style>