import { createRouter, createWebHistory } from 'vue-router'
import Dashboard from '@/views/Dashboard'
import Login from '@/views/Login'
import Users from '@/views/Users'
import Scripts from '@/views/Scripts'
import Companies from '@/views/Companies'
import Tickets from '@/views/Tickets'
import SuggestedRobots from '@/views/SuggestedRobots'
import FakeCards from '@/views/FakeCards'
// import Emails from '@/views/Emails'
import AdminUsers from '@/views/AdminUsers'
import ErrorLogs from '@/views/ErrorLogs'
import Roboteka from '@/views/Roboteka'
import TaskRecorder from '@/views/TaskRecorder'
import FlowLogs from '@/views/FlowLogs'
import BrowserFlows from '@/views/BrowserFlows'
import BrowserFlowLogs from '@/views/BrowserFlowLogs.vue'
import FlowsCompiler from '@/views/FlowsCompiler.vue'
import FlowsManagement from '../views/FlowsManagement.vue'


const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/users',
    name: 'Users',
    component: Users
  },
  {
    path: '/scripts',
    name: 'Scripts',
    component: Scripts
  },
  {
    path: '/groups',
    name: 'Groups',
    component: Companies
  },
  {
    path: '/tickets',
    name: 'Tickets',
    component: Tickets
  },
  {
    path: '/suggestedRobots',
    name: 'Suggested Robots',
    component: SuggestedRobots
  },
  {
    path: '/fakeCards',
    name: 'Fake Cards',
    component: FakeCards
  },
  // {
  //   path: '/emails',
  //   name: 'Emails',
  //   component: Emails
  // },
  {
    path: '/adminusers',
    name: 'Admin Users',
    component: AdminUsers
  },
  {
    path: '/errorlogs',
    name: 'Error Logs',
    component: ErrorLogs
  },
  {
    path: '/roboteka',
    name: 'Roboteka',
    component: Roboteka
  },
  {
    path: '/taskrecorder',
    name: 'Task Recorder',
    component: TaskRecorder
  },
  {
    path: '/flowlogs',
    name: 'Flow Logs',
    component: FlowLogs
  },
  {
    path: '/browserflows',
    name: 'Browser flows',
    component: BrowserFlows
  },
  {
    path: '/browserflow-stats',
    name: 'Browser flow stats',
    component: BrowserFlowLogs,
  },
  {
    path: '/flows-compiler',
    name: 'Flows compiler',
    component: FlowsCompiler,
  },
  {
    path: '/flows-management',
    name: 'Flows management',
    component: FlowsManagement,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
