<template>
    <div class="contextBar">
        <div class="backButton" @click="this.$emit('back')">
            <i class="fi fi-sr-angle-left"></i>
        </div>
        <p class="currentView" v-if="this.user._id">View user details</p>
        <p class="currentView" v-else>Create user</p>
    </div>

    <div class="userViewLayout">

        <div class="userDetails">

            <div class="userDataAndImg">

                <div class="userImg">
                    <i class="fi fi-rr-user"></i>
                </div>

                <div class="userData">

                    <div class="userName">
                        {{this.user.name}}
                        <div class="userTags">
                            <div class="userTag" v-if="this.user.admin">Admin</div>
                            <div class="userTag" v-if="this.user.subscription" style="background: var(--cta-100)">{{this.UserSubscriptionType(this.user.subscription)}}</div>
                        </div>
                    </div>

                    <div class="userInfoContainer">
                        <div class="userInfo" @click="this.CopyToClipboard(this.user.email)">
                            <i class="fi fi-sr-envelope"></i>
                            <p class="text">{{this.user.email ?? '-'}}</p>
                        </div>

                        <div class="userInfo" @click="this.CopyToClipboard(this.user.phoneExtension+this.user.phone)">
                            <i class="fi fi-sr-address-book"></i>
                            <p class="text" style="font-size: 13px; letter-spacing: 0.3px">{{this.user.phoneExtension}}{{this.user.phone ?? '-'}}</p>
                        </div>

                        <div class="userInfo" @click="this.user.company ? this.showCompanyModal = true : undefined">
                            <i class="fi fi-sr-building"></i>
                            <p class="text">{{this.user.company ? this.user.company.name : '-'}}</p>
                        </div>
                    </div>

                    <div class="userMetrics">
                        <div class="metric">
                            <p class="value">{{this.user.userMetric ? Math.ceil(Object.values(this.user.userMetric.items)[0].totalTimeSaved / 3600000) : '0'}}hrs</p>
                            <p class="title">Time saved</p>
                        </div>

                        <div class="metric">
                            <p class="value">{{this.user.userMetric ? Object.values(this.user.userMetric.items)[0].documentsGenerated : '???'}}</p>
                            <p class="title">Docs created</p>
                        </div>

                        <div class="metric">
                            <p class="value">{{this.user.userMetric ? Object.values(this.user.userMetric.items)[0].scriptsExecuted : '???'}}</p>
                            <p class="title">Scripts launched</p>
                        </div>
                    </div>
                </div>

            </div>

            <div class="userViewSectionMenu">
                <div class="userViewSection" @click="this.OnMenuElementClick($event.currentTarget, 0)" ref="initialSection" :class="{'selected' : this.currentSection === 0}" v-if="this.user._id">Overview</div>
                <div class="userViewSection" @click="this.OnMenuElementClick($event.currentTarget, 1)" ref="createInitialSection" :class="{'selected' : this.currentSection === 1}" v-if="this.CurrentUser.user.role.name==='Admin'">Settings</div>
                <div class="userViewSection" @click="this.OnMenuElementClick($event.currentTarget, 2)" :class="{'selected' : this.currentSection === 2}" v-if="this.user._id">Logs | Events</div>

                <div class="menuMark" ref="menuMark"/>
            </div>
        </div>

        <div class="userSectionContent" v-if="this.currentSection === 0">

            <div class="sectionPanel">
                <div class="sectionTitle">OVERVIEW</div>

                <div class="userInfoOverview">

                    <div class="userInfoContainer">
                        <div class="userInfo">
                            <p>Full Name</p>
                            <p class="data">{{this.user.name}}</p>
                        </div>

                        <div class="separator"/>

                        <div class="userInfo">
                            <p>Email Address</p>
                            <p class="data">{{this.user.email}}</p>
                        </div>

                        <div class="separator"/>

                        <div class="userInfo">
                            <p>Company</p>
                            <p class="data">{{this.user.company ? this.user.company.name : ''}}</p>
                        </div>

                        <div class="separator"/>

                        <div class="userInfo">
                            <p>Contact Phone</p>
                            <p class="data" style="font-size: 14px">{{this.user.phoneExtension}} {{this.user.phone}}</p>
                        </div>

                        <div class="separator"/>
                        <div class="userInfo">
                            <p>Joined Date</p>
                            <p class="data" style="font-size: 14px">{{this.FormatDate(this.user.createAt)}}</p>
                        </div>

                        <div class="separator"/>

                        <div class="userInfo">
                            <p>Language</p>
                            <p class="data">{{this.user.language}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="userSectionContent" v-else-if="this.currentSection === 1">

            <div class="sectionPanel">
            
                <div class="sectionTitle">SETTINGS</div>

                <div class="userInputContainer">

                    <div class="userInput">
                        <p class="label">User Name</p>
                        <input type="text" placeholder="Enter user name" spellcheck="false" v-model="this.user.name">
                    </div>

                    <div class="userInput">
                        <p class="label">Email Address</p>
                        <input type="text" placeholder="Enter user email" spellcheck="false" v-model="this.user.email">
                    </div>

                    <div class="userInput">
                        <p class="label">Phone</p>
                        <input type="text" placeholder="Enter user phone" spellcheck="false" v-model="this.user.phone">
                    </div>

                    <div class="userInput">
                        <p class="label">Password</p>
                        <input type="password" placeholder="Enter user password" spellcheck="false" v-model="this.user.password">
                    </div>

                    <div class="userInput">
                        <p class="label">Company</p>
                        <input type="text" disabled placeholder="User without company assigned" spellcheck="false" :value="this.user.company ? this.user.company.name : ''">
                        <button class="editButton"
                            @click="this.showCompanyModal = true">
                            {{ this.user.company ? 'SHOW DETAILS' : 'ASSIGN COMPANY'}}
                        </button>
                    </div>
                </div>

                <div class="sectionFooter" style="justify-content: flex-end">
                    <button class="generalButton secondary" @click="this.user = JSON.parse(JSON.stringify(this.initialData))">Reset</button>
                    <button class="generalButton primary" @click="ApplyButton()">Apply</button>
                </div>
            </div>
            
            <div class="sectionPanel" v-if="this.user.subscription">
                <div class="sectionTitle">SUBSCRIPTION</div>
            </div>

            <div class="sectionPanel" v-if="this.user._id">
                <div class="sectionTitle">DEACTIVATE ACCOUNT</div>

                <div style="display: flex; justify-content: flex-end; padding: 24px">
                    <button class="generalButton primary" @click="this.showDeactivateAccountModal = true">Deactivate Account</button>
                </div>
            </div>
        </div>

        <div class="userSectionContent" v-else-if="this.currentSection === 2">

            <div class="sectionPanel">
            
                <div class="sectionTitle">LOGS / EVENTS</div>
            </div>
        </div>
    </div>

    <!------ COMPANY MODAL ------>
    <div class="globalOverlay" v-if="this.showCompanyModal" @click="this.showCompanyModal = false">
        <div class="generalModal" @click="$event.stopPropagation()">

            <div class="generalModalHeader" style="justify-content: space-between" v-if="this.user.company">
                COMPANY DETAILS
                <button class="actionButton" style="">EDIT COMPANY</button>
            </div>

            <div class="generalModalHeader" v-else>
                ASSIGN COMPANY TO USER
            </div>

            <div class="generalModalContent" v-if="this.user.company">
                <div style="display: flex; flex-flow: column; gap: 8px">

                    <div class="companyInfo">
                        <p>Company name</p>
                        <div>
                            {{this.user.company.name}}
                        </div>
                    </div>
                    <div class="companyInfo">
                        <p>Create At</p>
                        <div>
                            {{this.FormatDate(this.user.company.createAt)}}
                        </div>
                    </div>
                    <div class="companyInfo">
                        <p>Last Update</p>
                        <div>
                            {{this.FormatDate(this.user.company.updateAt)}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!------ DEACTIVATE ACCOUNT MODAL ------>
    <div class="globalOverlay" v-if="this.showDeactivateAccountModal" @click="this.showDeactivateAccountModal = false">
        <div class="generalModal" @click="$event.stopPropagation()">
            <div class="generalModalHeader">
                DEACTIVATE ACCOUNT
                <i class="fi fi-rr-shield-exclamation" style="margin-left: 12px"></i>
            </div>

            <div class="generalModalContent">
                You are about to <b>deactivate</b> this user
                <br><br>
                Are you sure about that ?
                <br><br>
            </div>

            <div class="generalModalFooter" style="justify-content: flex-end">
                <button class="generalButton secondary" @click="this.DeactivateUser(user);this.showDeactivateAccountModal = false;this.$emit('back')">Yes</button>
                <button class="generalButton primary" @click="this.showDeactivateAccountModal = false">No</button>
            </div>
        </div>
    </div>
</template>

<script>
import { FormatDate,UserSubscriptionType } from '@/helpers/utils'
import {GetUserMetrics,DeleteUser} from '@/helpers/APIconnection'
import store from '@/store'

export default {

    props: [ 'userData' ],
    emits: [ 'back' ],

    data: function() {
        return {
            initialData: undefined, // User data backup
            user: {}, // User data
            userMetric:{},
            currentSection: null,
            showDeactivateAccountModal: false,
            showCompanyModal: false,
        }
    },

    mounted() {

        this.initialData = {...this.userData}
        this.user = JSON.parse(JSON.stringify(this.initialData))

        console.log(this.user)
        if(this.user._id){
            GetUserMetrics(this.user._id).then((response) => {
                this.userMetric=response.data
                console.log(this.userMetric)
            }).catch((error)=>{
                console.log(error)
            })
            this.OnMenuElementClick(this.$refs.initialSection)
        }else{
            
            this.OnMenuElementClick(this.$refs.createInitialSection,1)
        }
           
    },

    computed:{

        CurrentUser() { return store.getters.currentUser },
    },
    
    methods: {

        FormatDate,

        GetUserMetrics,

        UserSubscriptionType,

        OnMenuElementClick: function(menuElement, section){

            this.currentSection = section ?? 0

            if ( !menuElement ) return
            
            this.$refs.menuMark.style.left = menuElement.offsetLeft + 'px'
            this.$refs.menuMark.style.width = menuElement.offsetWidth + 'px'
        },

        CopyToClipboard: function(text) {

            if ( text ) {
                navigator.clipboard.writeText(text)
                store.commit('CreateFloatingMessage', { id: 'textCopied', text: 'Text copied!', time: 2000})
            }
        },

        ApplyButton: function(){
            if(!this.user._id){
                // CreateUser(this.user).then((response) => {
                //     console.log(response)
                //     this.$emit('back')
                // }).catch((error) => {
                //     console.log(error)
                // })

            }else{
                console.log('MOdificar')
                
            }


        },
        DeactivateUser:function(user){
            DeleteUser(user._id)
        }
    }
}
</script>

<style>
.backButton {
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 16px;
    padding-right: 2px;
    cursor: pointer;

    transition: background 0.2s;
}

.backButton:hover {
    background: var(--main-5);
}


.userViewLayout {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    padding: 24px 10%;
}

/*---------- USER DETAILS ----------*/
.userViewLayout .userDetails {
    width: 100%;
    display: flex;
    flex-flow: column;
    background: #FFF;
    border-radius: 8px;
    box-shadow: 1px 1px 3px hsla(0, 0%, 0%, 0.1);
}

.userViewLayout .userDetails .userDataAndImg {
    display: flex;
    gap: 24px;
    padding: 24px 24px 16px;
}

.userViewLayout .userDetails .userDataAndImg .userImg {
    height: 130px;
    width: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    background: var(--main-10);
    border-radius: 8px;
}

.userViewLayout .userDetails .userDataAndImg .userData {

    display: flex;
    flex-flow: column;
    justify-content: space-between;
}

.userViewLayout .userDetails .userDataAndImg .userData .userName {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-width: 100px;
    font-size: 20px;
    font-weight: 600;
    gap: 16px;
}

.userViewLayout .userDetails .userDataAndImg .userData .userName .userTags {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}

.userViewLayout .userDetails .userDataAndImg .userData .userTag {
    background: var(--second-100);
    color: #FFF;
    font-weight: 500;
    border-radius: 4px;
    padding: 4px 12px;
    font-size: 12px;
    font-weight: 700;
}


.userViewLayout .userDetails .userDataAndImg .userInfoContainer {
    
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    row-gap: 4px;
}

.userViewLayout .userDetails .userDataAndImg .userInfoContainer .userInfo {
    display: flex;
    gap: 6px;
    color: var(--main-50);
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    user-select: text;

    transition: color 0.2s;
}

.userViewLayout .userDetails .userDataAndImg .userInfoContainer .userInfo:hover {
    color: var(--second-100);
}


.userViewLayout .userDetails .userDataAndImg .userMetrics {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.userViewLayout .userDetails .userDataAndImg .userMetrics .metric {
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 4px;
    padding: 4px 8px;
    border: 1px dashed var(--main-50);
    border-radius: 4px;
}

.userViewLayout .userDetails .userDataAndImg .userMetrics .metric .value {
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    color: var(--main-75);
}

.userViewLayout .userDetails .userDataAndImg .userMetrics .metric .title {
    font-size: 12px;
    font-weight: 700;
    color: var(--main-50);
}


/*---------- USER SECTION MENU ----------*/
.userViewSectionMenu {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 0 12px;
    margin-top: 12px;
}

.userViewSectionMenu .userViewSection {
    font-size: 14px;
    font-weight: 600;
    color: var(--main-50);
    padding: 16px 24px;
    cursor: pointer;

    transition: color 0.2s;
}

.userViewSectionMenu .userViewSection:hover {
    color: var(--main-75);
}

.userViewSectionMenu .userViewSection.selected {
    color: var(--main-100);
}

.userViewSectionMenu .menuMark {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 3px;
    background: var(--main-100);
    transform: scaleX(0.8);

    transition: left 0.25s, width 0.3s;
}


/*---------- USER TOOLS ----------*/
.userViewLayout .userSectionContent {
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 16px;
}

.userViewLayout .userSectionContent .sectionPanel {
    min-height: 100px;
    width: 100%;
    background: #FFF;
    border-radius: 8px;
    box-shadow: 1px 1px 3px hsla(0, 0%, 0%, 0.1);
}

.userViewLayout .userSectionContent .sectionTitle {
    padding: 24px;
    font-weight: 700;
    border-bottom: 1px solid var(--main-10);
}

.userViewLayout .userSectionContent .sectionFooter {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 24px;
    font-weight: 700;
    border-top: 1px solid var(--main-10);
}


/* ---------- OVERVIEW SECTION ---------- */
.userViewLayout .userSectionContent .userInfoOverview {
    display: flex;
    flex-wrap: wrap;
}

.userViewLayout .userSectionContent .userInfoOverview .userInfoContainer {
    width: fit-content;
    display: flex;
    flex-flow: column;
    gap: 14px;
    padding: 24px;
}

.userViewLayout .userSectionContent .userInfoOverview .userInfo {
    display: flex;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
}

.userViewLayout .userSectionContent .userInfoOverview .userInfo p:first-child {
    font-size: 14px;
    font-weight: 500;
    color: var(--main-50);
    width: 200px;
}

.userViewLayout .userSectionContent .userInfoOverview .userInfo .data {
    font-size: 15px;
    font-weight: 500;
    color: var(--main-75);
    user-select: text;
}

.userViewLayout .userSectionContent .userInfoOverview .separator {
    height: 1px;
    width: 100%;
    background: #FAFAFA;
}


.userViewLayout .userSectionContent .userInputContainer {
    display: flex;
    flex-flow: column;
    gap: 16px;
    padding: 24px
}

.userViewLayout .userSectionContent .userInputContainer .userInput {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.userViewLayout .userSectionContent .userInput .label {
    font-size: 14px;
    font-weight: 700;
    color: var(--main-75);
    white-space: nowrap;
}

.userViewLayout .userSectionContent .userInput input[type=text],.userViewLayout .userSectionContent .userInput input[type=password],.userViewLayout .userSectionContent .userInput select{
    height: 42px;
    width: 50%;
    border: none;
    outline: none;
    padding: 0 20px;
    background: var(--main-5);
    color: var(--main-75);
    border: 1px solid transparent;
    font-weight: 600;
    font-size: 14px;
    border-radius: 4px;

    transition: border-color 0.2s;
}

.userViewLayout .userSectionContent .userInput input[type=text]:disabled ,.userViewLayout .userSectionContent .userInput input[type=password]:disabled, .userViewLayout .userSectionContent .userInput select:disabled{
    border-color: var(--main-10);
    background: #FAFAFA;
}

.userViewLayout .userSectionContent .userInput input[type=text]:focus ,.userViewLayout .userSectionContent .userInput input[type=password]:focus, .userViewLayout .userSectionContent .userInput select:focus{
    border-color: var(--main-50);
}

.userViewLayout .userSectionContent .userInput input[type=text]::placeholder ,.userViewLayout .userSectionContent .userInput input[type=password]::placeholder, .userViewLayout .userSectionContent .userInput select::placeholder{
    color: var(--main-50);
    font-weight: 500;
}

.userViewLayout .userSectionContent .userInput .editButton {
    display: none;
    position: absolute;
    right: 16px;
    gap: 4px;
    border: 1px solid var(--main-75);
    background: transparent;
    color: var(--main-100);
    padding: 4px 12px;
    border-radius: 2px;
    font-size: 10px;
    font-weight: 700;
    cursor: pointer;
    
    transition: background 0.2s, color 0.2s;
}

.userViewLayout .userSectionContent .userInput .editButton:hover {
    background: var(--main-75);
    color: #FFF;
}

.userViewLayout .userSectionContent .userInput:hover .editButton {
    animation: fade 0.3s;
    display: flex;
}


/* COMPANY DETAILS MODAL */
.actionButton {
    border-radius: 4px;
    background: var(--second-100);
    padding: 6px 12px;
    color: #FFF;
    font-size: 10px;
    font-weight: 700;
    cursor: pointer;
}

.actionButton:hover {
    box-shadow: 1px 1px 4px var(--main-50);
}

.companyInfo {
    width: 100%;
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
}

.companyInfo p:first-child {
    width: 180px;
    font-weight: 700;
    font-size: 12px;
}

.companyInfo div {
    width: 100%;
    display: flex;
    background: var(--main-5);
    border-radius: 2px;
    padding: 8px 12px;
    user-select: text;
}
</style>