import { createStore } from 'vuex'
import { version } from '../../package'

export default createStore({

  state: {
    appVersion: version,

    currentUser: {},
    isAuthenticated: false,

    floatingMessagesQueue: {},
    floatingMessagesQueueTimeouts: {},
    sendEmailParameters: undefined,
  },

  getters: {
    currentUser: state => {
      return state.currentUser
  },
    sendEmailParameters: state =>{
      return state.sendEmailParameters
    }
  },

  mutations: {
    SetCurrentUser(state, data) {
      Object.assign(state.currentUser, data)
      
  },
    
    CreateFloatingMessage(state, msgData) {

      // id
      // time
      // text

      if ( !msgData.id ) { return }

      if ( msgData.id in state.floatingMessagesQueueTimeouts ) { clearTimeout(state.floatingMessagesQueueTimeouts[msgData.id]) } // Reiniciar timeout
      msgData.time = msgData.time ?? 3000 // Tiempo por defecto
      state.floatingMessagesQueue[msgData.id] = msgData // Creación mensaje flotante
      state.floatingMessagesQueueTimeouts[msgData.id] = setTimeout(() => { this.commit('RemoveFloatingMessage', msgData.id) }, msgData.time)
    },

    RemoveFloatingMessage(state, id) {

      if ( id in state.floatingMessagesQueue ) { delete state.floatingMessagesQueue[id] }
    },

    ShowSendEmailModal(state, data) {
      
      state.sendEmailParameters = data
      
  },
  },

  actions: {
  },

  modules: {
  }
})
