<template>
    <div class="contextBar">
        <div class="backButton" @click="this.$emit('back')">
            <i class="fi fi-sr-angle-left"></i>
        </div>
        <p class="currentView">Browser Flow Log</p>
    </div>

    <div class="viewing-all">
        <div class="quick-info-bar">
            <div v-if="this.inspectingList">
                <p>Inspecting {{ this.inspectingList.name }}</p>
                <span @click="this.inspectingList = undefined" class="back-button">&larr; Back</span>
            </div>

            <div>
                <p class="title">Quick Log Info</p>
                <p>Timestamp: {{ FormatDate(viewingData.createAt, true)}}</p>
                <p>Flow group: <span :style="{ color: viewingData.browserFlow.group ? '' : 'gray' }">{{ viewingData.browserFlow.group?.name ?? '(No group)' }}</span></p>
                <p>Flow: {{ viewingData.browserFlow?.name }}</p>
                <p>Iterations: <span style="color: hsl(120deg 100% 60%)">{{ this.viewingData.successfulIterations }}</span> + <span style="color: hsl(0deg 100% 60%)">{{ this.viewingData.failedIterations }}</span> / {{ this.viewingData.totalIterations }}</p>
                <p>Result: {{ this.viewingData.result }}</p>
                <p @click="this.inspectingList = { name: 'main error list', list: this.viewingData.errorList }" class="selectable"
                    :class="{ selected: this.inspectingList?.name === 'main error list' }">Error count: {{ this.viewingData.errorList.length }}</p>
                <p>Log streams: <i v-if="!Object.keys(this.viewingData.logStreams ?? {}).length">(none)</i></p>
                <ul v-if="Object.keys(this.viewingData.logStreams ?? {}).length">
                    <li v-for="logStreamOutput, logStreamKey of this.viewingData.logStreams" :key="logStreamKey" @click="this.inspectingList = { name: logStreamKey, list: logStreamOutput }"
                        class="selectable" :class="{ selected: this.inspectingList?.name === logStreamKey }">
                        <span>{{ logStreamKey }}</span>
                        <i>{{ logStreamOutput.length }}</i>
                    </li>
                </ul>
            </div>

            <div v-if="this.viewingData.user != undefined">
                <p class="title">User Info</p>
                <p>Name: {{this.viewingData.user.name}}</p>
                <p>Email: {{this.viewingData.user.email}}</p>
                <p>Phone: {{this.viewingData.user.phone}}</p>
                <p>CompanyName: {{this.viewingData.user.companyName}}</p>
                <p>CompanyRole: {{this.viewingData.user.companyRole}}</p>
            </div>
        </div>

        <div v-if="this.inspectingList" class="scroll-vertical">
            <div>
                <div class="log-grid">
                    <template v-for="element, index in this.inspectingList.list" :key="element">
                        <div class="center-child">
                            <span class="sticky-vertical">{{ index }}</span>
                        </div>
                        <div class="scroller">
                            <pre>{{ element }}</pre>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div v-if="!this.inspectingList" class="viewing-content">
            <p>Reduced info, use left controls to select logs</p>
            <div class="scroll-vertical">
                <pre>{{ Object.assign({}, this.viewingData, { errorList: undefined, logStreams: undefined }) }}</pre>
            </div>
        </div>
    </div>

</template>

<script>
import { FormatDate } from '@/helpers/utils';
export default {
    props: [ 'viewingData' ],

    data: function(){
        return {
            inspectingList: undefined,
        };
    },
    methods: {
        FormatDate,
    },
};
</script>

<style scoped>
.viewing-all {
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 16px;
    padding: 24px;
    margin: 24px;
    border-radius: 4px;
    background: white;
}
.quick-info-bar {
    display: flex;
    flex-direction: column;
    gap: 8px;
    > * {
        display: flex;
        flex-direction: column;
        gap: 4px;
        user-select: text;
        .title {
            font-weight: bold;
            font-size: 20px;
        }
    }
}
.viewing-content {
    width: 100%;
    height: 100%;
}
ul {
    margin-left: 12px;
    > :first-child::after {
        content: ": ";
    }
}
.selectable {
    cursor: pointer;
    color: hsl(240deg 100% 40%);
    &.selected {
        color: hsl(90deg 100% 20%);
    }
    &:hover {
        color: hsl(90deg 100% 30%);
    }
}
i {
    display: unset;
}
.log-grid {
    display: grid;
    grid-template-columns: max-content 1fr;
    border: 1px solid hsl(210deg 100% 50%);
    > * {
        padding: 4px;
        border: 1px solid hsl(210deg 100% 50%);
    }
    > .scroller {
        min-width: 0px;
        overflow: auto;
    }
}
.center-child {
    display: grid;
    align-items: center;
    text-align: center;
}
.back-button {
    background-color: hsl(45deg 100% 80%);
    text-align: center;
    padding: 0 8px;
    border-radius: 4px;
    align-self: center;
    cursor: pointer;
}
.scroll-vertical {
    height: 100%;
    display: flex;
    flex-direction: column;
    > * {
        height: 0;
        flex-grow: 1;
        overflow-y: scroll;
    }
}
.sticky-vertical {
    position: sticky;
    top: 0;
    bottom: 0;
}
</style>