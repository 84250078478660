<template>
<div class="ticketView">
    <div class="contextBar">
        <div class="backButton" @click="this.$emit('back')">
            <i class="fi fi-sr-angle-left"></i>
        </div>
        <p class="currentView">Ticket</p>
    </div>

    <div class="ticketDialog" v-if="!this.loader">

        <div class="mainTicket">

            <div class="mainTicketTitle">Main Ticket <span style="font-size:16px">({{FormatDate(this.ticket.createAt)}})</span></div>
            <div class="ticketUser">
                <p class="ticketUserTitle"> USER</p>
                <div class="ticketUserData">
                    <p>{{this.ticketData.user.name}}</p>
                    <p>{{this.ticketData.user.email}}</p>
                </div>
                
                
            </div>
            <div class="ticketSubjet">
                <p class="ticketSubjetTitle"> SUBJECT</p>
                <p class="ticketSubjetData">{{this.ticket.subjet}}</p>
            </div>
            <div class="ticketBody">
                <p class="ticketBodyTitle"> BODY</p>
                <p class="ticketBodyData">{{this.ticket.body}}</p>
            </div>


        </div>

        <div class="repliesTicket" v-if="this.ticket && this.ticket.replies.length>0">
            <div class="repliesTicketTitle">Replies</div>
            <div v-for="replie in this.ticket.replies" :key="replie" class="ticketRepliesContainer" :class="replie.user ? replie.user.email!==this.ticketData.user.email?  'admin':'':''">
                <div class="replie">
                    <div class="replieTitle">{{FormatDate(replie.createAt)}}</div>
                    <div class="ticketUser">
                        
                        <div class="ticketUserData">
                            <p>{{replie.user ?replie.user.name: 'Name not found'}} - {{replie.user ?replie.user.email : 'Email not found'}}</p>
                        </div>
                    </div>
                    
                    <div class="ticketBody">
                        
                        <p class="ticketBodyData">{{replie.message}}</p>
                    </div>

                </div>
            </div>

        </div>

        <div class="responseTicket">

            <div class="repliesTicketTitle">Send Response</div>

            <textarea v-model="this.message"> </textarea>

           
                <button class="generalButton primary" @click="this.SendTicketMessage()"> SEND</button>
                <button class="generalButton primary" @click="this.CloseTicket()"> CLOSE TICKET</button>
            

        </div>

    </div>
    <div v-else class="bigLoadingSpinner main center"></div>
</div>
  
</template>

<script>
import {GetUserTicketByID,SendResponseToTicket,CloseTicket} from '@/helpers/APIconnection'
import {FormatDate} from '@/helpers/utils'
import store from '@/store'
export default {
    props: [ 'ticketData' ],
    emits: [ 'back' ],

    data: function() {
        return {
            ticket:{},
            message:"",
            store,
            loader:true
            
        }
    },
    mounted(){
        
        GetUserTicketByID(this.ticketData.id).then((response) =>{
            console.log(response.data)
            this.ticket=response.data
            this.loader=false
        }).catch((error)=>{
            console.log(error)
        })
        console.log(this.ticket)
    },
    methods:{
        FormatDate,

        SendTicketMessage:function(){
            console.log(this.ticket)
            SendResponseToTicket(this.ticketData.id,{message:this.message}).then((response)=>{
                console.log(response)
                let body = {
                message:this.message,
                user:this.store.getters.currentUser.user,
                createAt:Date.now()
            }
            this.message=""
            this.ticket.replies.push(body)
                
            }).catch((error) => {

                console.log(error)
            })
            
            
            
        },
        CloseTicket:function(){
            CloseTicket(this.ticketData.id)
                .catch((error) => {
                    console.log(error)
                })
        }
    }

}
</script>

<style>
.ticketView{
    display: flex;
    flex-direction: column;
    
}

.ticketDialog{
    padding: 12px;
    display: flex;
    flex-direction: column;
    
    gap: 12px;
    justify-content: center;
    align-items: center;
    
    
}

.ticketDialog .mainTicket{
    background: white;
    padding: 16px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 50%;

}
.mainTicketTitle, .replieTitle{
    font-weight: 600;
    font-size: 24px;
}


.ticketUser{
    display: flex;
    flex-direction: column;
    gap: 8px;

}
.ticketUser .ticketUserTitle{
    font-weight: 500;
    font-size: 20px;

}

.ticketUser .ticketUserData{
    display: flex;
    flex-direction: column;
    font-size: 14px;
}

.ticketSubjet{
    display: flex;
    flex-direction: column;
    gap: 8px;

}
.ticketSubjet .ticketSubjetTitle{
    font-weight: 500;
    font-size: 20px;

}

.ticketSubjet .ticketSubjetData{
    display: flex;
    flex-direction: column;
    background: var(--main-10);
    padding: 12px;
    border-radius: 8px;
}
.ticketBody{
    display: flex;
    flex-direction: column;
    gap: 8px;

}
.ticketBody .ticketBodyTitle{
    font-weight: 500;
    font-size: 20px;

}


.ticketBody .ticketBodyData{
    display: flex;
    flex-direction: column;
    background: var(--main-10);
    padding: 12px;
    border-radius: 8px;
}

.repliesTicket{
    
    
    
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 50%;
    
}

.repliesTicket .repliesTicketTitle{
    font-weight: 600;
    font-size: 24px;
}

.replie{
    
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-bottom: 12px;
    background: white;
    border-radius: 8px;
}

.responseTicket{
    background: white;
    padding: 16px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 50%;
}
.responseTicket .repliesTicketTitle{
    font-weight: 600;
    font-size: 24px;
}

.responseTicket textarea{
    background: var(--main-10);
    padding: 16px;
    resize: none;
    outline: none;
    height: 300px;
    width: 100;
    color: var(--main-100);
}



</style>