<template>
    <div class="contextBar">
        <div class="backButton" @click="this.$emit('back')">
            <i class="fi fi-sr-angle-left"></i>
        </div>
        <p class="currentView">Task Recorder</p>
    </div>

    <div class="suggested-robot">
        <div class="user-info">
            <p class="title">Task Recorder Info</p>
            <p>Email: {{this.taskRecorderData.username}}</p>
            <p>Description: {{this.taskRecorderData.appVersion}}</p>
            <p>Date: {{ FormatDate(taskRecorderData.insertTime, taskRecorderData.insertTime)}}</p>
            <p>Mesagge: {{this.taskRecorderData.message}}</p>
            <p>Method: {{this.taskRecorderData.method}}</p>
            <p>StackTrace: {{this.taskRecorderData.stackTrace}}</p>
            <p v-if="this.taskRecorderData.innerExceptions != undefined">EXCEPTION: {{this.taskRecorderData.innerExceptions}}</p>




            
            
        </div>
       
    </div>

</template>

<script>
import { FormatDate } from '@/helpers/utils'
export default {
    props: [ 'taskRecorderData' ],

    data:function(){
        return{

        }
    },
    methods: {
        FormatDate}
    
}


</script>
<style>

.suggested-robot{
    display:flex;
    flex-direction: column;
    gap:8px;
    padding: 24px;
    margin: 24px;
    border-radius: 4px;
    background: white;
    

}
.user-info{
    display: flex;
    flex-direction: column;
    gap:4px;
    user-select: text;
}
.user-info .title{
    font-weight: bold;
    font-size: 20px;

}
</style>