<template>
    <div style="display: flex; gap: 10px;">
        <span style="display: grid; align-content: center;" @click="this.displayList = !this.displayList">
            <span style="font-weight: bold;">{{ this.listTitle }}</span>
            <span>({{ this.listOptions.filter(elementAndStatus => elementAndStatus[1]).length }}/{{this.listOptions.length}})</span>
        </span>
        <input v-model="this.filter" style="flex-grow: 1; padding: 4px;" @focus="this.displayList = true"/>
        <button @click="this.filter = ''; this.listOptions.forEach(elementAndStatus => elementAndStatus[1] = false); this.$emit('optionsCleared');" class="generalButton">Clear</button>
    </div>
    <div class="list-scroll" style="max-height: 300px; overflow: auto;" v-if="this.displayList">
        <template v-if="!this.filter">
            <div v-for="elementAndStatus, index of this.listOptions" :key="elementAndStatus" @click="this.$emit('optionChanged', index)">
                <slot :index="index" :status="elementAndStatus[1]" :element="elementAndStatus[0]" :filter="this.filter"/>
            </div>
        </template>
        <template v-else>
            <!--
            <div v-for="elementAndStatus, index of this.listOptions" :key="elementAndStatus" @click="this.$emit('optionChanged', index)">
                <div v-if="this.toFilter(elementAndStatus[0]).map(e => e.includes(this.filter))">
                    <slot :index="index" :status="elementAndStatus[1]" :element="elementAndStatus[0]" :filter="this.filter"/>
                </div>
            </div>
            -->
            <div v-for="elementAndStatus of this.filteredByLevels" :key="elementAndStatus" @click="this.$emit('optionChanged', elementAndStatus[symbolIndex])">
                <slot :index="elementAndStatus[symbolIndex]" :status="elementAndStatus[1]" :element="elementAndStatus[0]" :filter="this.filter"/>
            </div>
        </template>
    </div>
</template>

<script>
const symbolSorted = Symbol('symbol-sorted');
const symbolIndex = Symbol('symbol-index');
export default {
    props: [ 'listTitle', 'listOptions', 'toFilter' ],
    watch: {
        listOptions: function(newVal, oldVal) {
            console.log('listOptions', { newVal, oldVal });
        },
    },
    emits: [ 'optionChanged', 'optionsCleared' ],
    data: function() {
        return {
            filter: '',
            displayList: false,
            symbolIndex,
        };
    },
    computed: {
        filteredByLevels: function() {
            const filterLowerCase = this.filter.toLowerCase();
            this.listOptions.map((elementAndStatus, index) => {
                const values = this.toFilter(elementAndStatus[0]);
                if (values.some(value => value === this.filter)) {
                    elementAndStatus[symbolSorted] = 10;
                } else if (values.some(value => value.toLowerCase() === filterLowerCase)) {
                    elementAndStatus[symbolSorted] = 9;
                } else if (values.some(value => value.startsWith(this.filter))) {
                    elementAndStatus[symbolSorted] = 8;
                } else if (values.some(value => value.toLowerCase().startsWith(filterLowerCase))) {
                    elementAndStatus[symbolSorted] = 7;
                } else if (values.some(value => value.includes(this.filter))) {
                    elementAndStatus[symbolSorted] = 6;
                } else if (values.some(value => value.toLowerCase().includes(filterLowerCase))) {
                    elementAndStatus[symbolSorted] = 5;
                } else if (values.some(value => new RegExp(this.filter.split(/\W/).map(this.escapeRegExp).join('.*?')).test(value))) {
                    elementAndStatus[symbolSorted] = 4;
                } else if (values.some(value => new RegExp(this.filter.split(/\W/).map(this.escapeRegExp).join('.*?'), 'i').test(value))) {
                    elementAndStatus[symbolSorted] = 3;
                } else if (values.some(value => new RegExp(this.filter.split('').map(this.escapeRegExp).join('.*?')).test(value))) {
                    elementAndStatus[symbolSorted] = 2;
                } else if (values.some(value => new RegExp(this.filter.split('').map(this.escapeRegExp).join('.*?'), 'i').test(value))) {
                    elementAndStatus[symbolSorted] = 1;
                } else {
                    elementAndStatus[symbolSorted] = 0;
                }
                elementAndStatus[symbolIndex] = index;
            });
            return this.listOptions.filter(elementAndStatus => elementAndStatus[symbolSorted]).sort((a, b) => b[symbolSorted] - a[symbolSorted]);
        },
    },
    methods: {
        escapeRegExp: function(string) {
            return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
        },
    },
}
</script>
