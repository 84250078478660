import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import { secureTranslation } from './i18n'


const app = createApp(App)
app.use(i18n)
app.config.globalProperties.$st = secureTranslation

let scrollValue
let componentReference


app.config.globalProperties.$saveCurrentScroll = (t) => {
    
    componentReference = t
    scrollValue = document.querySelector('#app').scrollTop

    setTimeout(() => document.querySelector('#app').scrollTo({top: 0}), 10)
}

app.config.globalProperties.$backToPreviousScroll = (t) => {

    if ( t === componentReference ) {

        setTimeout(() => document.querySelector('#app').scrollTo({top: scrollValue ?? 0}), 10)
    }

    componentReference = undefined
}

app.use(store).use(router).mount('#app')