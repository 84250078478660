<template>
    <div class="contextBar">
        <div class="backButton" @click="this.$emit('back')">
            <i class="fi fi-sr-angle-left"></i>
        </div>
        <p class="currentView">Flow Log</p>
    </div>

    <div class="suggested-robot">
        <div class="flex flex-row justify-between">
            <div class="user-info">
                <p class="title">Flow Log Info</p>
                <p>Flow: {{ this.flowlogData.browserFlowName }}</p>
                <p>Result: {{this.flowlogData.result}}</p>
                <p>Failed Iterations: {{this.flowlogData.failedIterations}}</p>
                <p>Successful Iterations: {{ flowlogData.successfulIterations}}</p>
                <p>Total Iterations: {{ flowlogData.totalIterations}}</p>
                <p>Date: {{ FormatDate(flowlogData.createAt, flowlogData.createAt)}}</p>
            </div>
            <div class="user-info" v-if="this.flowlogData.user != undefined" >
                <p class="title">User Info</p>
                <p>Name: {{this.flowlogData.user.name}}</p>
                <p>Email: {{this.flowlogData.user.email}}</p>
                <p>Phone: {{this.flowlogData.user.phone}}</p>
                <p>CompanyName: {{this.flowlogData.user.companyName}}</p>
                <p>CompanyRole: {{this.flowlogData.user.companyRole}}</p>
            </div>
        </div>
        <div class="user-info">
            <p class="title">Error List:</p>
            <pre class="whitespace-pre-wrap"><code>{{ this.flowlogData.errorList }}</code></pre>
        </div>
    </div>

</template>

<script>
import { FormatDate } from '@/helpers/utils'
export default {
    props: [ 'flowlogData' ],

    data:function(){
        return{

        }
    },
    methods: {
        FormatDate}
    
}


</script>
<style>

.suggested-robot{
    display:flex;
    flex-direction: column;
    gap:8px;
    padding: 24px;
    margin: 24px;
    border-radius: 4px;
    background: white;
    

}
.user-info{
    display: flex;
    flex-direction: column;
    gap:4px;
    user-select: text;
}
.user-info .title{
    font-weight: bold;
    font-size: 20px;

}
.flex {
    display: flex;
}
.flex-row {
    flex-direction: row;
}
.justify-between {
    justify-content: space-between;
}
.whitespace-pre-wrap {
    white-space: pre-wrap;
}
</style>