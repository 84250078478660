<template>
    <div class="contextBar">
        <div class="backButton" @click="this.$emit('back')">
            <i class="fi fi-sr-angle-left"></i>
        </div>
        <p class="currentView">{{this.cardData.script.ref}} </p>
        
    </div>

    <div class="userViewLayout">

        <div class="userDetails">

            <div class="userDataAndImg">

                <div class="imageContainer">
                    <img :src="GetTaskImg(this.cardData.script.ref)">
                    
                </div>

                <div class="userData">

                    <div class="userName">{{this.$st(this.cardData.script.name)}}
                        <div class="userTags">
                            <div class="userTag">{{this.cardData.script.scriptVersion ? this.cardData.script.scriptVersion.version : '0.0.0'}}</div> 
                        </div>
                    </div>

                    <div>{{this.$st(this.cardData.script.shortDesc)}}</div>
                    
                </div>

            </div>

            <table class="usersTable" v-if="this.cardData.users">
                <tr>
                    <th>USER</th>
                    <th>PHONE</th>
                    <th>REQUEST DATE</th>

                </tr>
                    
                <tr v-for="user in this.cardData.users" :key="user" @click="this.ShowUserView(user)">

                    <td>
                        <div class="user">
                            <div class="userImg">
                                <i class="fi fi-rr-user"></i>
                            </div>
                            <div class="userInfo">
                                <p class="userName">{{user.name}}</p>
                                <p class="userEmail" >{{user.email}}</p>
                            </div>
                        </div>
                    </td>
                    <td class="phone">{{user.phone ? user.phoneExtension + user.phone : '-'}}</td>
                    <td class="joinedDate">{{this.FormatDate(user.requestDate)}}</td>
                </tr>
                    <!-- <div v-else style="height:400px"><div class="bigLoadingSpinner main center"></div></div> -->
            </table>
        </div>

    </div>
</template>

<script>
import {GetTaskImg,FormatDate} from '@/helpers/utils'
import { UserSubscriptionType } from '@/helpers/utils'
export default {
    
    props: [ 'cardData' ],
    emits: [ 'back' ],

    data:function(){
        return{

        }
    },

    methods:{    
        GetTaskImg,
        FormatDate,
        UserSubscriptionType
    }
}
</script>

<style>

</style>