<template>
    <div>
        <div class="contextBar">
            <div class="backButton" @click="this.$emit('back')">
                <i class="fi fi-sr-angle-left"></i>
            </div>
            <p class="currentView">Flow data</p>
        </div>
        <div class="suggested-robot">
            <div class="two-columns">
                <b style="text-align: center; grid-area: 1 / 1 / 2 / 3;">{{ this.flowId }}</b>
                <template v-for="current, key of { prod: currentProd, dev: currentDev }" :key="key">
                    <div class="grid-column" :style="`grid-column-start: ${key === 'prod' ? 1 : 2};`">
                        <b>{{ key === 'prod' ? 'Production' : 'Development' }}</b>
                        <div style="display: flex; gap: 4px; align-items: center;">
                            <div style="background-color: hsl(210deg 100% 80%); padding: 4px; border-radius: 8px;">
                                <div>Copy to {{ key === 'prod' ? 'development' : 'production' }}</div>
                                <div style="display: flex; gap: 4px;">
                                    <span class="actionButton" @click="this.FullCopyToOther(key)">Full</span>
                                    <span v-if="this.currentDev && this.currentProd" class="actionButton" @click="this.CodeCopyToOther(key)">Code</span>
                                    <span v-else class="actionButton disabled" title="Partial transfers require having data on both sides">Code</span>
                                    <span v-if="this.currentDev && this.currentProd" class="actionButton" @click="this.InfoCopyToOther(key)">Info</span>
                                    <span v-else class="actionButton disabled" title="Partial transfers require having data on both sides">Info</span>
                                </div>
                            </div>
                            <span class="actionButton" @click="this.RevertChanges(key)">Revert changes</span>
                            <span class="actionButton" @click="this.SaveChanges(key)">Save changes</span>
                        </div>
                        <template v-if="current">
                            <div class="input-label-list">
                                <div>
                                    <label>Name:</label>
                                    <input v-model="current.name"/>
                                </div>
                                <div>
                                    <label>Description:</label>
                                    <textarea v-model="current.description"/>
                                </div>
                                <div>
                                    <label>Enabled:</label>
                                    <input type="checkbox" v-model="current.status"/>
                                </div>
                                <div>
                                    <label>Created at:</label>
                                    <input style="color: gray" readonly :value="this.FormatDate(current.createAt, true)"/>
                                </div>
                                <div>
                                    <label>Updated at:</label>
                                    <input style="color: gray" readonly :value="this.FormatDate(current.updateAt, true)"/>
                                </div>
                                <div>
                                    <label>Time saved ratio:</label>
                                    <input type="number" min="0" v-model="current.timeSavedRatio"/>
                                </div>
                                <div>
                                    <label>Usage ratio:</label>
                                    <input type="number" min="0" v-model="current.usageRatio"/>
                                </div>
                                <div>
                                    <label>Auto repeat errors:</label>
                                    <input type="number" min="0" v-model="current.autoRepeatRowErrorsLimit"/>
                                </div>
                                <div>
                                    <label>Instructions:</label>
                                    <input v-model="current.instructionsURL"/>
                                </div>
                                <div>
                                    <label>Template:</label>
                                    <input v-model="current.templateURL"/>
                                </div>
                                <div>
                                    <label>Access control:</label>
                                    <textarea :value="JSON.stringify(current.accessControl)" @input="this.IfParsesPaint($event.target.value, $event.target, value => FillAccessControl(value, fixed => current.accessControl = fixed))"/>
                                </div>
                                <div>
                                    <label>Group:</label>
                                    <select :value="current.group?._id" @input="current.group = $event.target.value ? JSON.parse(JSON.stringify((key === 'prod' ? prodGroups : devGroups)[$event.target.value])) : undefined" :title="current.group?._id">
                                        <option value="">None</option>
                                        <option v-for="groupData, groupId in key === 'prod' ? prodGroups : devGroups" :key="groupId" :value="groupData._id">{{ groupData.name }}</option>
                                    </select>
                                </div>
                                <div>
                                    <label>Code:</label>
                                    <input readonly :value="current._code"/>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <p style="color: red;">No data</p>
                        </template>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import { GetFlowManagementFlowGroups, UpdateFlowManagementFlow } from '../helpers/APIconnection';
import { FormatDate } from '@/helpers/utils';

export default {
    props: [ 'flowId', 'flowData' ],
    emits: [ 'updateList', 'back' ],
    data: function() {
        return {
            loader: false,
            currentProd: undefined,
            currentDev: undefined,
            prodGroups: {},
            devGroups: {},
        };
    },
    created: async function() {
        this.RevertChanges('prod');
        this.RevertChanges('dev');
        this.loader = true;
        const response = await GetFlowManagementFlowGroups();
        if (response.status === 200) {
            ({ prod: this.prodGroups, dev: this.devGroups } = response.data);
        }
        this.loader = false;
    },
    methods: {
        FormatDate,
        IfParses: function(value, cbOk, cbErr = () => {}) {
            if (value === '') {
                cbOk(null);
                return;
            }
            try {
                cbOk(JSON.parse(value));
            } catch (ignore) {
                cbErr();
            }
        },
        IfParsesPaint: function(value, node, cbOk, cbErr = () => {}) {
            this.IfParses(value, result => {
                cbOk(result);
                node.classList.remove('error');
            }, () => {
                cbErr();
                node.classList.add('error');
            });
        },
        FillAccessControl: function(value, cb) {
            if (!value) {
                cb(value);
                return;
            }
            cb(Object.assign({
                public: false,
                users: [],
                groups: [],
                userSectors: [],
                userLocations: [],
            }, value));
        },
        CodeCopyToOther: function(key) {
            if (key === 'prod' && this.currentProd && this.currentDev) {
                this.currentDev._code = this.currentProd._code;
            } else if (key === 'dev' && this.currentDev && this.currentProd) {
                this.currentProd._code = this.currentDev._code;
            }
        },
        InfoCopyToOther: function(key) {
            const keysToClone = [ 'name', 'description', 'status', 'timeSavedRatio', 'usageRatio', 'autoRepeatRowErrorsLimit', 'instructionsURL', 'templateURL', 'accessControl', 'group' ];
            if (key === 'prod' && this.currentProd && this.currentDev) {
                keysToClone.forEach(key => this.currentDev[key] = this.currentProd[key] ? JSON.parse(JSON.stringify(this.currentProd[key])) : this.currentProd[key]);
            } else if (key === 'dev' && this.currentDev && this.currentProd) {
                keysToClone.forEach(key => this.currentProd[key] = this.currentDev[key] ? JSON.parse(JSON.stringify(this.currentDev[key])) : this.currentDev[key]);
            }
        },
        FullCopyToOther: function(key) {
            if (key === 'prod' && this.currentProd) {
                this.currentDev = JSON.parse(JSON.stringify(this.currentProd));
            } else if (key === 'dev' && this.currentDev) {
                this.currentProd = JSON.parse(JSON.stringify(this.currentDev));
            }
        },
        RevertChanges: function(key) {
            if (key === 'prod') {
                this.currentProd = this.flowData.prod ? Object.assign(JSON.parse(JSON.stringify(this.flowData.prod)), { _code: 'prod.' + this.flowId }) : undefined;
            } else if (key === 'dev') {
                this.currentDev = this.flowData.dev ? Object.assign(JSON.parse(JSON.stringify(this.flowData.dev)), { _code: 'dev.' + this.flowId }) : undefined;
            }
        },
        SaveChanges: async function(key) {
            if (key === 'prod' && confirm('Do you want to save changes on PRODUCTION?\nThis action cannot be undone')) {
                await UpdateFlowManagementFlow('prod.' + this.currentProd._id, this.currentProd);
                this.$emit('updateList');
            } else if (key === 'dev' && confirm('Do you want to save changes on DEVELOPMENT?\nThis action cannot be undone')) {
                await UpdateFlowManagementFlow('dev.' + this.currentDev._id, this.currentDev);
                this.$emit('updateList');
            }
        },
    },
}
</script>
<style scoped>
.grid-column {
    grid-row: span 15;
    display: grid;
    grid-template-rows: subgrid;
    gap: 8px;
}
.input-label-list {
    display: contents;
    & > div {
        display: flex;
        gap: 4px;
        & > input:not([type="checkbox"]), & > textarea {
            width: 0;
            flex-grow: 1;
        }
    }
}
.two-columns {
    display: grid;
    grid-template-columns: repeat(2, minmax(auto, calc(1 / 2)));
    gap: 16px;
}
.error {
    color: hsl(0deg 100% 50%);
    background-color: hsl(0deg 100% 98%);
}
.actionButton.disabled {
    background-color: hsl(0 0% 70%);
    cursor: not-allowed;
}
</style>
