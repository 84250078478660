<template>
    <div class="floatingMessagesLayout">

        <div class="topFloatingMessages" v-if="this.GetFloatingMessages">

            <div class="floatingMessage" v-for="msg in this.GetFloatingMessages" :key="msg" :style="{'animation-duration': msg.time + 'ms'}">
                {{msg.text}}
            </div>
        </div>
    </div>
</template>

<script>
import store from '../store'

export default {

    computed: {

        GetFloatingMessages() { return store.state.floatingMessagesQueue }
    }
}
</script>

<style>

@keyframes floatingMessageDown {
    0% { transform: translateY(-100px); opacity: 0; }
    5% { transform: translateY(0); opacity: 1; }
    95% { transform: translateY(0); opacity: 1; }
    100% { transform: translateY(-100px); opacity: 0; }
}
.floatingMessagesLayout {
    position: fixed;
    inset: 0;
    pointer-events: none;
    z-index: 1000;
}

.floatingMessagesLayout .topFloatingMessages {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translate(-50%);
    display: flex;
    gap: 8px;
}

.floatingMessagesLayout .topFloatingMessages .floatingMessage {
    background: var(--main-100);
    color: #FFF;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 24px;
    box-shadow: 1px 1px 2px 1px var(--main-50);

    animation: floatingMessageDown forwards;
}
</style>