<template>
<div v-if="!this.itemSelected">
    <div class="contextBar">
        <p class="currentView">{{this.$route.name}}</p>

        <div class="titleInfoTagsContainer" style="margin-left: 24px">
            <div class="titleInfoTags" v-if="this.scriptsItems">
                {{this.scriptsItems.total}} request
            </div>
        </div>
    </div>
    <div class="usersListContainer" v-if='!this.loader'>
            <div class="responseConfigBar">
                <div class="searchBar">
                    <i class="fi fi-rr-search"></i>
                    <input type="text" spellcheck="false" v-model="this.searchText" @input="this.SearchAppScript()" placeholder="Search Script"  @focus="$event.currentTarget.parentElement.classList.add('focus')" @blur="$event.currentTarget.parentElement.classList.remove('focus')">
                </div>
                <div class="filters" >
                    <button class="filtersButton" @click="this.showFiltersContainer = !this.showFiltersContainer">
                        <i class="fi fi-rr-filter"></i>
                        Filters
                    </button>

                    <div class="filtersContainer" v-if='this.showFiltersContainer'>
                        <p class='filtersTitle'>Filters</p>

                        <div class="filterLine">
                            <div class="filter">
                                <div class="textFlex">
                                    <p> Created</p>
                                    <p class="spec">From</p>
                                </div>
                                <input type="date" v-model="this.filterValues.dateFrom" v-on:keydown.enter="this.GetScriptsFunction()">
                            </div>

                            <div class="filter" >
                                <div class="textFlex end">
                                    <p class="spec">To</p>
                                </div>
                                <input type="date" placeholder="01/03/2022" v-model="this.filterValues.dateTo" v-on:keydown.enter="this.GetScriptsFunction()">
                            </div>

                        </div>
                        

                        <div class="addFiltersButton">
                            <button class="generalButton primary" @click="this.GetScriptsFunction()">Search</button>
                        </div>
                    </div>
                </div>
            </div>

            <TableHeader :paginationValues="this.paginationValues" :entriesTotal="Object.keys(this.scriptsItems.ScriptRequestByScript).length" v-on:OnPaginationChange="Object.assign(this.paginationValues, $event); this.GetScriptsFunction()"/>

            <table class="usersTable" v-if="this.scriptsItems">
                <tr>
                    <th>SCRIPT</th>
                    <th>TOTAL REQUEST</th>
                    <th>LAST REQUEST</th>
                </tr>
                
                <tr v-for="scriptRequest in this.scriptsItems.ScriptRequestByScript" :key="scriptRequest" :class="this.tableLoader ? 'loading' : '' " @click="this.itemSelected=scriptRequest">
                    <td>
                        <div class="user">
                            <div class="userImg" style="border-radius:4px">
                                <img style="height:30px;width:75px" :src="this.GetTaskImg(scriptRequest.script.ref)" alt="">
                            </div>
                            <div class="userInfo">
                                <p class="userName">{{scriptRequest.script?.ref ?? '-'}}</p>
                                <p class="userEmail" >{{this.$st(scriptRequest.script.name)}}</p>
                            </div>
                        </div>
                    </td>
                    <td>{{scriptRequest.total ?? '0'}}</td>
                    <td>{{FormatDate(scriptRequest.users[0].requestDate)}}</td>
                </tr>
                
            </table>

        </div>
</div>

<FakeCardView v-else :cardData="this.itemSelected" v-on:back="this.itemSelected=undefined"/>
</template>

<script>
import {GetFakeCards} from '@/helpers/APIconnection'
import {GetTaskImg,SwitchFieldSorting} from '@/helpers/utils'
import TableHeader  from '@/components/TableHeader'
import { FormatDate } from '@/helpers/utils'
import FakeCardView from '@/components/FakeCardView'
export default {

    components :{ TableHeader, FakeCardView },

    data:function(){
            return{
                itemSelected:null,
                scriptsItems:[],
                listItemSelected:[],
                showFiltersContainer:false,
                loader:true,
                tableLoader:false,
                searchText:'',
                paginationValues:  {
                    entriesLimit: 100,
                    entriesStart: 0,
                },
                sortValues: {createAt: 'desc'},
                filterValues: {isfake:true},
                
            }

        },

        created(){
            GetFakeCards(this.filterValues, this.sortValues, this.paginationValues.entriesStart, this.paginationValues.entriesLimit).then((response)=>{  
                this.scriptsItems=response.data
                this.loader=false
                
            }).catch((error)=>{
                console.log(error.response)
            })
        },

        methods:{
            GetTaskImg,
            FormatDate,
            SwitchFieldSorting,

            GetScriptsFunction:function(){

                this.tableLoader=true
                this.showFiltersContainer=false
                
                GetFakeCards(this.filterValues, this.sortValues, this.paginationValues.entriesStart, this.paginationValues.entriesLimit).then((response) => {
                    this.tableLoader=false
                    this.scriptsItems = response.data 
                }).catch((error) => {
                    console.log(error)
                    this.tableLoader=false
                })
            },
        }

    }
</script>

<style>

</style>