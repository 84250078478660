<template>

    <div v-if="!this.selectedUser">

        <div class="contextBar" style="justify-content:space-between">

            <div style="display:flex">
                <p class="currentView">{{this.$route.name}}</p>

                <div class="titleInfoTagsContainer" style="margin-left: 24px">
                    <div class="titleInfoTags" v-if="this.users">
                        {{this.users.total}} users
                    </div>

                    <div class="titleInfoTags" title="New users in the last 30 days" v-if="this.users">
                        {{this.newUsers}} new users
                    </div>
                </div>
            </div>
            
            <!-- <div class="contextBarButtonsContainer">
                <button v-if="this.listItemSelected.length>0 && this.CurrentUser.user.role.name==='Admin'" class="generalButton secondary" @click="this.SendEmail()">
                    Delete Users
                </button>
                <button v-if="this.listItemSelected.length>0" class="generalButton secondary" @click="this.SendEmail()">
                    Send Email
                </button>
                <button class="generalButton primary" v-if="this.CurrentUser.user.role.name==='Admin'" @click="this.selectedUser={}">
                    New User
                </button>
            </div> -->

        </div>

        <div class="usersListContainer" v-if="!this.loader">

            <div class="responseConfigBar">
                <div class="searchBar">
                    <i class="fi fi-rr-search"></i>
                    <input type="text" spellcheck="false" v-model="this.searchText" placeholder="Search User" @input="this.SearchAppUser()"  @focus="$event.currentTarget.parentElement.classList.add('focus')" @blur="$event.currentTarget.parentElement.classList.remove('focus')">
                </div>
                <div class="filters" >
                    <button class="filtersButton" @click="this.showFiltersContainer = !this.showFiltersContainer">
                        <i class="fi fi-rr-filter"></i>
                        Filters
                    </button>

                    <div class="filtersContainer" v-if='this.showFiltersContainer'>
                        <p class='filtersTitle'>Filters</p>

                        <div class="filterLine">
                            <div class="filter">
                                <div class="textFlex">
                                    <p> Join Date</p>
                                    <p class="spec">From</p>
                                </div>
                                <input type="date" v-model="this.filterValues.dateFrom" v-on:keydown.enter="this.GetUsersFunction()">
                            </div>

                            <div class="filter" >
                                <div class="textFlex end">
                                    <p class="spec">To</p>
                                </div>
                                <input type="date" placeholder="01/03/2022" v-model="this.filterValues.dateTo" v-on:keydown.enter="this.GetUsersFunction()">
                            </div>

                        </div>
                        

                        <div class="addFiltersButton">
                            <button class="generalButton primary" @click="this.GetUsersFunction()">Search</button>
                        </div>
                    </div>
                </div>
            </div>

            <TableHeader :paginationValues="this.paginationValues" :entriesTotal="this.users.total" v-on:OnPaginationChange="Object.assign(this.paginationValues, $event); this.GetUsersFunction()"/>

            
                <table class="usersTable" v-if="this.users">
                    <tr>
                        <th @click="$event.stopPropagation()"><div class="checkbox" :class="{'checked':this.listItemSelected.length>0}" @click="this.ToogleAllCheckBox()"><i class="fi fi-br-check" ></i></div></th>
                        <th>USER</th>
                        <th>PHONE</th>
                        <th><div @click="this.SwitchFieldSorting(this.sortValues, 'createAt'), this.paginationValues.entriesStart = 0; this.GetUsersFunction()" :class="this.sortValues['createAt']"> JOINED DATE <i class="fi fi-br-arrow-down"></i></div></th>
                        <th>LAST LOGIN</th>
                        <th>SUBSCRIPTION</th> 
                        <th>COMPANY</th>
                        <th style="text-align: center;">MENU</th>
                    </tr>
                    
                    <tr v-for="user in this.users.users ?? this.users.items" :key="user" @click="this.ShowUserView(user)">
                        <td @click="$event.stopPropagation()"><div class="checkbox" :class="{'checked':this.listItemSelected.includes(user)}" @click="this.ToggleCheckBox(user);$event.stopPropagation()"><i class="fi fi-br-check"></i></div></td>
                        <td>
                            <div class="user">
                                <div class="userImg">
                                    <i class="fi fi-rr-user"></i>
                                </div>
                                <div class="userInfo">
                                    <p class="userName">{{user.name}}</p>
                                    <p class="userEmail" >{{user.email}} <i class="fi fi-br-check" v-if="user.emailValid" title="Email validate"></i> </p>
                                </div>
                            </div>
                        </td>
                        <td class="phone">{{user.phone ? user.phoneExtension + user.phone : '-'}}</td>
                        <td class="joinedDate">{{this.FormatDate(user.createAt)}}</td>
                        <td class="lastLogin"><div>{{user.userMetrics ? this.DateSince(user.userMetrics.lastLogin) : '-'}}</div></td>
                        <td class="subscription"><div :class="user.subscription ? this.UserSubscriptionType(user.subscription) : ''.toLowerCase()"> {{user.subscription ? this.UserSubscriptionType(user.subscription) : 'basic'}}</div></td>
                        <td class="company">{{user.group ? user.group.name : '-'}}</td>
                        <td class="menuIconContainer" @click="$event.stopPropagation()">
                            <!-- <div class="menuIcon" @click="SendEmail(user)">
                                <i class="fi fi-sr-envelope"></i>
                            </div> -->
                            <div class="menuIcon" v-if="this.CurrentUser.user.role.name==='Admin'" @click="this.clickedUser = user;this.showDeactivateAccountModal = true">
                                <i class="fi fi-sr-trash"></i>
                            </div>
                            </td>
                    </tr>
                    <!-- <div v-else style="height:400px"><div class="bigLoadingSpinner main center"></div></div> -->
                </table>
            
            
        </div>
        <div v-else class="bigLoadingSpinner main center"></div>
    </div>

    <UserView v-else :userData="this.selectedUser" v-on:back="this.HideUserView()"/>
    
    <!------ DEACTIVATE USER MODAL ------>
    <div class="globalOverlay" v-if="this.showDeactivateAccountModal" @click="this.showDeactivateAccountModal = false">
        <div class="generalModal" @click="$event.stopPropagation()">
            <div class="generalModalHeader">
                DEACTIVATE ACCOUNT
                <i class="fi fi-rr-shield-exclamation" style="margin-left: 12px"></i>
            </div>

            <div class="generalModalContent">
                You are about to <b>deactivate</b> this user
                <br><br>
                Are you sure about that?
                <br><br>
            </div>

            <div class="generalModalFooter" style="justify-content: flex-end">
                <button class="generalButton secondary" @click="this.DeactivateUser(clickedUser);this.showDeactivateAccountModal = false;this.SearchAppUser()">Yes</button>
                <button class="generalButton primary" @click="this.clickedUser = undefined;this.showDeactivateAccountModal = false">No</button>
            </div>
        </div>
    </div>
</template>

<script>
import { FormatDate, SwitchFieldSorting, UserSubscriptionType } from '@/helpers/utils'
import { GetUsers, SearchFunction, DeleteUser } from '@/helpers/APIconnection'
import UserView from '@/components/UserView'
import TableHeader  from '@/components/TableHeader'
import store from '@/store'

export default {
    data() {
        return {
            users: undefined,
            selectedUser: undefined,
            newUsers: 0,
            currentScroll: 0,
            listItemSelected:[],
            showFiltersContainer:false,
            searchText:'',
            loader:true,
            tableloader:false,
            paginationValues:  {
                entriesLimit: 100,
                entriesStart: 0,
            },
            sortValues: {createAt: 'desc'},
            filterValues: {},
            showDeactivateAccountModal:false,
            clickedUser: undefined
        }
    },

    created(){
        //GetUsersFunction()
        GetUsers(this.filterValues, this.sortValues, this.paginationValues.entriesStart, this.paginationValues.entriesLimit).then((response) => { this.users = response.data; this.GetNewUsers();this.loader=false}).catch(() => {})
    },

    components: { UserView, TableHeader },

    computed:{

        CurrentUser() { return store.getters.currentUser },
    },

    methods: {

        FormatDate,

        SwitchFieldSorting,

        UserSubscriptionType,

        GetNewUsers: function() {

            this.newUsers = 0
            Object.values(this.users.users).forEach((user) => {
                if ( Math.floor((new Date() - new Date(user.createAt)) / 1000) / 86400 <= 30 ) { this.newUsers++ }
            })
        },

        ShowUserView: function(user) {

            this.selectedUser = user

            this.$saveCurrentScroll(this)
        },

        HideUserView: function() {

            this.selectedUser = undefined
            this.SearchAppUser()
            
            this.$backToPreviousScroll(this)
        },

        DateSince: function(date) {

            const seconds = Math.floor((new Date() - new Date(date)) / 1000);

            let interval = seconds / 31536000;

            if (interval > 1) { return Math.floor(interval) + ( interval < 2 ? ' year' : ' years' ) }
            interval = seconds / 2592000

            if (interval > 1) { return Math.floor(interval) + ( interval < 2 ? ' month' : ' months' ) }
            interval = seconds / 86400

            if (interval > 1) { return Math.floor(interval) + ( interval < 2 ? ' day' : ' days' ) }
            interval = seconds / 3600

            if (interval > 1) { return Math.floor(interval) + ( interval < 2 ? ' hour' : ' hours' ) }
            interval = seconds / 60

            if (interval > 1) { return Math.floor(interval) + ( interval < 2 ? ' minute' : ' minutes' ) }

            return 'now';
        },

        // SendEmail:function(user){
        //     console.log(user)
        //     if(this.listItemSelected.length>0 && !user){
        //         store.commit('ShowSendEmailModal',{user:this.listItemSelected})
        //     }else{
        //         store.commit('ShowSendEmailModal',{user:[user]})
        //     }
            
        // },

        SearchAppUser:function(){
            
            clearTimeout(this.searchTimeOut)

            if(this.searchText==''){
                this.GetUsersFunction()
                return}
    
                this.searchTimeOut = setTimeout(() => { 
                
                SearchFunction('users',this.searchText).then((response) => {
                    
                    this.users=response.data
                }).catch((error)=> {
                    console.log(error)
                    this.GetUsersFunction()
                })

            },500)

        },

        GetUsersFunction:function(){

            this.tableloader=true
            this.showFiltersContainer=false
            GetUsers(this.filterValues, this.sortValues, this.paginationValues.entriesStart, this.paginationValues.entriesLimit).then((response) => {
                
                this.users = response.data 
                this.tableloader=false
            }).catch((error) => {
                console.log(error)
                this.tableloader=false
            })
        
        
        },

        // UserSubscriptionType:function(subscription){


        //         // Suscripción cancelada sin tiempo restante
        //         if ( subscription.stripeSubStatus === 'canceled' ) { return 'basic' }

        //         // Suscripción activa
        //         else if ( subscription.stripeSubStatus === 'active' ) { return 'pro' }

        //         // Suscripción cancelada con tiempo restante mayor o igual a ahora mismo
        //         else if ( subscription.stripeSubStatus === 'active' && subscription.cancelAt && ( new Date(subscription.cancelAt * 1000) >= new Date() ) ) { return 'pro_canceled' }

        //         // Suscripción de prueba + suscripción
        //         else if ( subscription.stripeSubStatus === 'trialing' && subscription.upcomingInvoice ) { return 'pro' }

        //         // Suscripción de prueba
        //         else if ( subscription.stripeSubStatus === 'trialing' && !subscription.upcomingInvoice ) { return 'trialing' }

        //         else { return 'basic' }

        // },

        ToggleCheckBox:function(object){
            
            var indexOf= this.listItemSelected.indexOf(object)
            if(indexOf>-1){
                this.listItemSelected.splice(indexOf,1)
            }else{
                this.listItemSelected.push(object)
            }
        },

        ToogleAllCheckBox:function(){
           
            if(this.listItemSelected.length>0){
                this.listItemSelected=[]
            }else{   
                this.listItemSelected= [...this.users.users]  
            }
            
        },
        DeactivateUser:function(user){
            DeleteUser(user._id)
        }
    },
}
</script>

<style>

.usersListContainer {
    padding: 24px 48px;
    margin: 24px;
    border-radius: 12px;
    background: #FFF;
}

.usersTable {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
}

tr.highlighted {
    border: 1px solid red !important;
}

.usersTable tr:not(:first-child):nth-child(odd) {
    background: #FAFAFA;
}

.usersTable tr:not(:first-child):hover {
    background: var(--second-5);
}

.usersTable tr:not(:first-child):not(:last-child) {
    border: solid;
    border-width: 1px 0;
    border-color: var(--main-10);
}
.usersTable tr.loading{
    opacity: 0.5;
    pointer-events: none;
}

.usersTable th {
    color: var(--main-75);
    padding: 12px 8px;
    text-align: start;
    font-size: 12px;
    font-weight: 700;
}
.usersTable th div {

  display: flex;

  align-items: center;
  gap: 4px;
  border-radius: 4px;
  padding: 4px 4px 4px 8px;
  cursor: pointer;
  transition: background 0.2s;
}

.usersTable th div:hover {
  background: var(--main-50);
}

.usersTable th div.asc, .usersTable th div.desc {
  background: var(--main-10);
}

.usersTable th div i {
  opacity: 0;
  transition: opacity 0.2s, transform 0.2s;
}

.usersTable th div.desc i {
  opacity: 1;
}

.usersTable th div.asc i {
  opacity: 1;
  transform: rotateX(180deg);
}

.usersTable td {
    padding: 4px 8px;
    text-align: start;
    user-select: text;
}


/* ====== Slang user table ====== */

.slangUsersTable {
    align-items: center;
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
}

.slangUsersTable tr:not(:first-child):nth-child(odd) {
    background: #FAFAFA;
}

.slangUsersTable tr:not(:first-child):not(:last-child) {
    border: solid;
    border-width: 1px 0;
    border-color: var(--main-10);
}

.slangUsersTable th {
    color: var(--main-75);
    padding: 12px 8px;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
}

.slangUsersTable td {
    padding: 4px 0px;
    text-align: center;
    user-select: text;
}

/* ========== checkbox ========== */
.checkbox{
    height: 16px;
    width: 16px;
    border: 1px solid var(--main-100);
    border-radius: 2px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FAFAFA;
}

.checkbox.checked{
    border: transparent;
    background: var(--second-100);
}

/* ========== USER ========== */
.usersTable .user {
    display: flex;
    align-items: center;
    gap: 8px;
}

.usersTable .user .userInfo {
    display: flex;
    flex-flow: column;
    gap: 2px;
    padding: 12px 8px;
}

.usersTable .user .userImg {
    min-height: 36px;
    min-width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(--main-10);
}

.usersTable .user .userInfo .userName {
    font-size: 14px;
    font-weight: 600;
}

.usersTable .user .userInfo .userEmail {
    display:flex;
    gap:12px;
    font-size: 12px;
    color: var(--main-75);
}

.usersTable .user .userInfo .userEmail i{
    font-size: 8px;
    border-radius: 8px;
    background: var(--main-10);
    padding: 4px;
}

/* ========== PHONE ========== */
.usersTable .phone {
    font-size: 13px;
    letter-spacing: 0.2px;
}


/* ========== SUBSCRIPTION ========== */
.usersTable .subscription div {
    height: 32px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--main-10);
    font-size: 12px;
    font-weight: 700;
    border-radius: 4px;
    user-select: none;
}

.usersTable .subscription div.pro {
    background: var(--cta-10);
    color: var(--cta-100);
}


/* ========== SUBSCRIPTION ========== */
.usersTable .lastLogin div {
    height: 24px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--main-10);
    border-radius: 4px;
    font-size: 12px;
}


/* ========== COMPANY ========== */
.usersTable .company {
    font-size: 12px;
    color: var(--main-75);
}

/* ===========MENU ICON ========= */
.menuIconContainer{
    display: flex;
    justify-content: center;
    gap: 6px;

}
.menuIconContainer .menuIcon{
    padding: 8px;
    background: var(--main-10);
    border-radius: 8px;
    cursor: pointer;

}


.responseConfigBar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
}

.responseConfigBar .searchBar {
    height: 32px;
    width: 20%;
    min-width: 150px;
    display: flex;
    align-items: center;
    border: 1px solid var(--main-10);
    border-radius: 4px;
    background: var(--main-5);
    overflow: hidden;

    transition: box-shadow 0.2s, border-color 0.2s;
}

.responseConfigBar .searchBar.focus {
    border-color: var(--main-50);
    box-shadow: 0 0 3px var(--main-10);
}

.responseConfigBar .searchBar i {
    color: var(--main-50);
    margin: 0 12px;

    transition: color 0.2s;
}

.responseConfigBar .searchBar.focus i {
    color: var(--main-75);
}

.responseConfigBar .searchBar input {
    height: 100%;
    width: 100%;
    outline: none;
    font-size: 14px;
    font-weight: 600;
    color: var(--main-75);
    background: transparent;
}

.responseConfigBar .searchBar input::placeholder {
    font-size: 13px;
    font-weight: 400;
    color: var(--main-50);
}

.responseConfigBar .filters{
    position: relative;
    z-index: 100;
}

.responseConfigBar .filters .filtersButton {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 100%;
    padding: 0 12px;
    background: var(--second-100);
    color: #FFF;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    outline: none;

    transition: box-shadow 0.3s;
}

.responseConfigBar .filters.filtersButton:hover {
    box-shadow: 1px 1px 2px var(--main-50);
}

.responseConfigBar .filters .filtersButton i {
    color: #FFF;
}

.filtersContainer{
    height: fit-content;
    width:  fit-content;
    min-width: 250px;
    right: 0;
    margin-top: 10px;
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    border: 1px solid var(--second-100);;
    border-radius: 4px;
    opacity: 1;
    background: var(--main-10);
    animation: swipeUp 0.3s;
    
}

.filtersTitle{
    font-weight: bold;
    color: var(--second-100);
}
.addFiltersButton{
    display: flex;
    flex-direction: row-reverse;
}

.filter {
  position: relative;
  height: 60px;
  width: fit-content;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  z-index: 10;
}

.filter .textFlex {
  height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter .textFlex.end {
  justify-content: flex-end;
}

.filter p {
  height: 24px;
  color: var(--primary-100);
}

.filter p:first-child {
  padding-left: 2px;
}

.filter .textFlex .spec {
  font-size: 12px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 5px;
}

.filter input {
  padding-left: 10px;
  outline: none;
  color: var(--main_100);
  height: 36px;
}

.filter input::placeholder {
  font-size: 14px;
  color: var(--main_50);
}

.filter select {

  padding:0  10px;
  outline: none;
  color: var(--main-75);
  height: 36px;
}



.filter .entriesSearch {
  position: absolute;
  top: calc(100% + 4px);
  left: 1px;
  width: calc(100% - 2px);
  background: #FFF;
  box-shadow: 0 2px 8px hsla(0, 0%, 0%, 0.5);
  z-index: 10px;
  overflow-y: scroll;
  max-height: 400px;
}



.filter .entriesSearch div {
  font-size: 12px;
  padding: 8px;
  min-width: 100%;
  user-select: none;
  cursor: pointer;
  animation: showUp 0.3s;
}

.filter .entriesSearch div:hover {
  background: var(--main_10);
}
.filterLine{
    display: flex;
    gap: 12px;
}
</style>