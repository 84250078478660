import { createI18n } from 'vue-i18n'
import { GetTranslations} from '@/helpers/APIconnection'

let translationsMessages = []

// Custom translation method to avoid undefined errors, this will be used as vue global property ($ct)
export function secureTranslation(text, fields = {}, isPlural) {
  
  if (text) { return i18n.global.t(text, fields, isPlural) }
}

export async function InitializeDefaultTranslation() {

  // const locale = process.env.IS_ELECTRON ? 'en' : navigator.language.split('-')[0] || 'en'
  const locale = 'en'
  await SetI18nLanguage(locale)
}

export async function GetTranslation(locale) { // # Get language messages

  const response = await GetTranslations(locale)
  
  if ( response && response.status === 200 ) {
    
    i18n.global.setLocaleMessage(locale, response.data)
    translationsMessages.push(locale)
    return true
  }
}

export async function SetI18nLanguage(locale) { // # Change translation

  
  if ( !translationsMessages.includes(locale) ) {
    const response = await GetTranslation(locale)
    if ( !response ) { return } // Translation request failed
  }

  i18n.global.locale.value = locale
  document.querySelector('html').setAttribute('lang', locale)
}

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: 'en',
  fallbackLocale: 'en',
  missingWarn: false,
  fallbackWarn: false,
  warnHtmlMessage: false,
  messages: {}
})

export default i18n