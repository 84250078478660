<template>
    <div v-if='!this.itemSelected'>
        <div class="contextBar">
            <p class="currentView">{{this.$route.name}}</p>

            <div class="titleInfoTagsContainer" style="margin-left: 24px">
                <div class="titleInfoTags" v-if="this.scriptsItems">
                    {{this.scriptsItems.total}} scripts
                </div>

                <!-- <div class="titleInfoTags" title="New users in the last 30 days">
                    {{this.newUsers}} new users
                </div> -->
            </div>
        </div>
        <div class="usersListContainer" v-if='!this.loader'>
            <div class="responseConfigBar">
                <div class="searchBar">
                    <i class="fi fi-rr-search"></i>
                    <input type="text" spellcheck="false" v-model="this.searchText" @input="this.SearchAppScript()" placeholder="Search Script"  @focus="$event.currentTarget.parentElement.classList.add('focus')" @blur="$event.currentTarget.parentElement.classList.remove('focus')">
                </div>
                <div class="filters" >
                    <button class="filtersButton" @click="this.showFiltersContainer = !this.showFiltersContainer">
                        <i class="fi fi-rr-filter"></i>
                        Filters
                    </button>

                    <div class="filtersContainer" v-if='this.showFiltersContainer'>
                        <p class='filtersTitle'>Filters</p>

                        <div class="filterLine">
                            <div class="filter">
                                <div class="textFlex">
                                    <p> Created</p>
                                    <p class="spec">From</p>
                                </div>
                                <input type="date" v-model="this.filterValues.dateFrom" v-on:keydown.enter="this.GetScriptsFunction()">
                            </div>

                            <div class="filter" >
                                <div class="textFlex end">
                                    <p class="spec">To</p>
                                </div>
                                <input type="date" placeholder="01/03/2022" v-model="this.filterValues.dateTo" v-on:keydown.enter="this.GetScriptsFunction()">
                            </div>

                        </div>
                        

                        <div class="addFiltersButton">
                            <button class="generalButton primary" @click="this.GetScriptsFunction()">Search</button>
                        </div>
                    </div>
                </div>
            </div>

            <TableHeader :paginationValues="this.paginationValues" :entriesTotal="this.scriptsItems.total" v-on:OnPaginationChange="Object.assign(this.paginationValues, $event); this.GetScriptsFunction()"/>

            <table class="usersTable" v-if="this.scriptsItems">
                <tr>
                    <th @click="$event.stopPropagation()"><div class="checkbox" :class="{'checked':this.listItemSelected.length>0}" @click="this.ToogleAllCheckBox()"><i class="fi fi-br-check"></i></div></th>
                    <th>SCRIPT</th>
                    <th><div @click="this.SwitchFieldSorting(this.sortValues, 'translatedName');" :class="this.sortValues['translatedName']">NAME<i class="fi fi-br-arrow-down"></i></div></th>
                    <th><div @click="this.SwitchFieldSorting(this.sortValues, 'totalExecutions'); " :class="this.sortValues['totalExecutions']">TOTAL EXECUTIONS  <i class="fi fi-br-arrow-down"></i></div></th>
                    <th><div @click="this.SwitchFieldSorting(this.sortValues, 'successExecutions'); " :class="this.sortValues['successExecutions']">SUCCESS EXECUTIONS <i class="fi fi-br-arrow-down"></i></div></th>
                    <th><div @click="this.SwitchFieldSorting(this.sortValues, 'distinctUserCount'); " :class="this.sortValues['distinctUserCount']">DISTINCT USER COUNT <i class="fi fi-br-arrow-down"></i></div></th>
                    <th><div @click="this.SwitchFieldSorting(this.sortValues, 'lastExecution'); " :class="this.sortValues['lastExecution']"> LAST EXECUTION DATE TIME<i class="fi fi-br-arrow-down"></i></div></th>
                    <th><div @click="this.SwitchFieldSorting(this.sortValues, 'createAt'); " :class="this.sortValues['createAt']"> CREATED AT <i class="fi fi-br-arrow-down"></i></div></th>
                    <th>VERSION</th>
                    <th><div @click="this.SwitchFieldSorting(this.sortValues, 'updateAt'); " :class="this.sortValues['updateAt']"> UPDATE AT <i class="fi fi-br-arrow-down"></i></div></th>
                    <th>TYPE</th>
                    <th style="text-align: center;">MENU</th>
                </tr>
                
                <tr v-for="script in this.GetOrderScripts" :key="script" @click="this.$saveCurrentScroll(this); this.itemSelected=script"  :class="this.tableLoader ? 'loading' : '' ">
                    <td @click="$event.stopPropagation()"><div class="checkbox" :class="{'checked':this.listItemSelected.includes(script)}" @click="this.ToggleCheckBox(script);$event.stopPropagation()"><i class="fi fi-br-check"></i></div></td>
                    <td>
                        <div class="user">
                            <div class="userImg" style="border-radius:4px">
                                <img style="height:30px;width:75px" :src="this.GetTaskImg(script.ref)" alt="">
                            </div>
                            <div class="userInfo">
                                <p class="userName">{{script.ref}}</p>
                            </div>
                        </div>
                    </td>
                    <td>{{script.translatedName}}</td>
                    <td>{{script.totalExecutions ?? '0'}}</td>
                    <td>{{script.successExecutions ?? '-'}}</td>
                    <td>{{script.distinctUserCount ?? '0'}}</td>
                    <td>{{script.lastExecution != '0' ? this.FormatDate(script.lastExecution,true) :'-'}}</td>
                    <td>{{script.createAt ? this.FormatDate(script.createAt) : '-'}}</td>
                    <td>{{script.scriptVersion ? script.scriptVersion.version : '-'}}</td>
                    <td>{{script.scriptVersion ? this.FormatDate(script.scriptVersion.updateAt) : '-'}}</td>
                    <td>{{script.type ? script.type=='py'? 'Python': 'Node' : '-'}}</td>
                    
                    <td class="menuIconContainer" @click="$event.stopPropagation()">
                        <!-- <div class="menuIcon" @click="SendEmail(user)">
                            <i class="fi fi-sr-envelope"></i>
                        </div> -->
                        <div class="menuIcon" >
                            <i class="fi fi-sr-trash"></i>
                        </div>
                        </td>
                </tr>
                
            </table>

        </div>
        <div v-else class="bigLoadingSpinner main center"></div>
    </div>
    <ScriptView v-else :scriptData="this.itemSelected" v-on:back="this.itemSelected = undefined; this.$backToPreviousScroll(this)"/>
</template>

<script>
import {GetScripts,SearchFunction} from '@/helpers/APIconnection'
import {GetTaskImg,SwitchFieldSorting} from '@/helpers/utils'
import ScriptView from '@/components/ScriptView'
import TableHeader  from '@/components/TableHeader'
import { FormatDate } from '@/helpers/utils'
export default {
    data:function(){     
        return{
            itemSelected:null,
            scriptsItems:[],
            listItemSelected:[],
            showFiltersContainer:false,
            loader:true,
            tableLoader:false,
            searchText:'',
            paginationValues:  {
                entriesLimit: 100,
                entriesStart: 0,
            },
            sortValues: {createAt: 'desc'},
            filterValues: {},
            
        }
        
    },
    components: { ScriptView, TableHeader },

    computed: {
        
        
        GetOrderScripts() {
            let scripts = [...this.scriptsItems.items];
            let sortKeys = ['translatedName', 'totalExecutions', 'successExecutions', 'distinctUserCount','lastExecution', 'createAt'];
            for (let key of sortKeys) {
                if (this.sortValues[key]) {
                    scripts.sort((a, b) => {
                    let comparation;
                    if (typeof a[key] === 'string' || a[key] instanceof String) {
                        comparation = a[key].localeCompare(b[key]);
                    } else if (a[key] instanceof Date) {
                        comparation = a[key].getTime() - b[key].getTime();
                    } else {
                        comparation = a[key] - b[key];
                    }
                    return this.sortValues[key] === 'asc' ? comparation : comparation * -1;
                    });
                    break;
                }
            }
            return scripts;
        }
    },
    created(){
        GetScripts(this.filterValues, this.sortValues, this.paginationValues.entriesStart, this.paginationValues.entriesLimit).then((response)=>{   
            this.scriptsItems=response.data
            this.scriptsItems.items.forEach(script => script.translatedName = this.$st(script.name))
            this.loader=false
           
        }).catch((error)=>{
            console.log(error.response)
        }) 
    },

    methods:{
     
      GetTaskImg,
      FormatDate,
      SwitchFieldSorting,

       
        GetScriptsFunction:function(){

            this.tableLoader=true
            this.showFiltersContainer=false
            
            GetScripts(this.filterValues, this.sortValues, this.paginationValues.entriesStart, this.paginationValues.entriesLimit).then((response) => {
                this.tableLoader=false
                this.scriptsItems = response.data
                this.scriptsItems.items.forEach(script => script.translatedName = this.$st(script.name))           
            }).catch((error) => {
                console.log(error)
                this.tableLoader=false
            })
        
        
        },

        SearchAppScript:function(){
            
            clearTimeout(this.searchTimeOut)

            if(this.searchText==''){
                this.GetScriptsFunction()
                return}
    
                this.searchTimeOut = setTimeout(() => { 
                
                SearchFunction('scripts',this.searchText).then((response) => {

                    this.scriptsItems=response.data
                    this.scriptsItems.items.forEach(script => script.translatedName = this.$st(script.name))

                }).catch((error)=> {
                    console.log(error)
                    this.GetScriptsFunction()
                })

            },500)

        },
      
      ToggleCheckBox:function(object){
            
            var indexOf= this.listItemSelected.indexOf(object)
            if(indexOf>-1){
                this.listItemSelected.splice(indexOf,1)
            }else{
                this.listItemSelected.push(object)
            }
            
        },

        ToogleAllCheckBox:function(){
           
            if(this.listItemSelected.length>0){
                this.listItemSelected=[]
            }else{   
                this.listItemSelected= [...this.scriptsItems.items]  
            }
            
        }  
    },
    
}
</script>

<style>

.scriptsItemsContainer{
    
    background: #fff;
    padding: 24px 48px;
    margin: 24px;
    border-radius: 12px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}
.scriptItem{

    height: 250px;
    width: 250px;
    border: 1px black solid;
    border-radius: 16px;
    cursor: pointer;
}

.scriptItem .imgContainer{
    height: 150px;
    background: var(--main-100);
    border-radius: 16px 16px 0 0;
    overflow: hidden;
}

.imgContainer .scriptImg{
    
    width: 100%;
}
.scriptItem .textContainer{
    padding: 10px;
    display: flex;
    flex-direction: column;
    
    
    
}

.scriptItem .textContainer .title{
    
    font-size: 20px;
    font-weight: bold;
    color: var(--main-100);
}



</style>
