<template>

<div class="contextBar">
        <div class="backButton" @click="this.$emit('back')">
            <i class="fi fi-sr-angle-left"></i>
        </div>
        <p class="currentView">{{this.script.ref}} </p>
        
    </div>

    <div class="userViewLayout">

        <div class="userDetails">

            <div class="userDataAndImg">

                <div class="imageContainer">
                    <img :src="GetTaskImg(this.script.ref)">
                    
                </div>

                <div class="userData">

                    <div class="userName">{{this.$st(script.name)}}
                        <div class="userTags">
                            <div class="userTag">{{this.script.scriptVersion ? this.script.scriptVersion.version : '0.0.0'}}
                        </div> 
                            </div>
                        </div>

                    <div>{{this.$st(script.shortDesc)}}</div>
                    

                    <div class="userMetrics">
                        <div class="metric">
                            <p class="value">{{this.script.totalExecutions ?? 0}}</p>
                            <p class="title">Executions</p>
                        </div>

                        <div class="metric">
                            <p class="value">{{this.script.successExecutions ?? 0}}</p>
                            <p class="title"> Success Executions</p>
                        </div>

                        <div class="metric">
                            <p class="value">{{this.scriptMetrics.total>0 ? Math.ceil(Object.values(this.scriptMetrics.items)[0].totalTimeSaved / 3600000): '0'}}</p>
                            <p class="title">Hours saved</p>
                        </div>

                        <div class="metric">
                            <p class="value">{{this.script.lastExecution != '0' ? this.FormatDate(script.lastExecution,true) :'-'}}</p>
                            <p class="title"> Last Execution Date Time</p>
                        </div>

                        <div class="metric">
                            <p class="value">{{this.script.distinctUserCount ?? 0}}</p>
                            <p class="title">Distinct users</p>
                        </div>

                    </div>
                </div>

            </div>

            <div class="userViewSectionMenu">
                <div class="userViewSection" @click="this.OnMenuElementClick($event.currentTarget, 0)" ref="initialSection" :class="{'selected' : this.currentSection === 0}">Categories | Tags</div>
                <div class="userViewSection" @click="this.OnMenuElementClick($event.currentTarget, 1)" :class="{'selected' : this.currentSection === 1}">General Texts</div>
                <!-- <div class="userViewSection" @click="this.OnMenuElementClick($event.currentTarget, 2)" :class="{'selected' : this.currentSection === 2}">Logs | Events</div> -->

                <div class="menuMark" ref="menuMark"/>
            </div>
        </div>

        <div class="userSectionContent" v-if="this.currentSection === 0">

            <div class="sectionPanel">
                <div class="sectionTitle">Categories and Tags</div>
                <div class="scriptModal"> 
                    <div class="scriptCategories">
                        <div style="display:flex;gap:12px">
                            <p class="title">Categories</p>
                            <i class="fi fi-rr-pencil" style="cursor:pointer" @click="this.editCategories=true"></i> 
                        </div>
                        <div class="inLineElementsContainer">
                            <div class="listElement" v-for="categorie in this.script.categories" :key="categorie" > 
                            {{categorie.name}}
                            
                            </div>
                        </div>
                    </div>

                    <div class="globalOverlay center" v-if="this.editCategories" @click="this.editCategories=false">
                        <div class="modalEditElements" @click="$event.stopPropagation()">
                            <p class="title">Edit Categories</p>
                            <div class="editList">

                                
                                <div class="editListElement" :class="{'selected': this.scriptCategories.includes(category.id)}" v-for="category in this.categories" :key="category" @click="ToogleCheckCategory(category.id)">
                                    <p>{{category.name}}</p>

                                </div>
                            </div>
                            <div class="editListButtons">
                                <button class="generalButton primary">Guardar</button>
                            </div>

                        </div>
                        
                    </div>

                    
                    <div class="scriptTags">
                        <div style="display:flex;gap:12px">
                            <p class="title">Tags</p>
                            <i class="fi fi-rr-pencil" style="cursor:pointer" @click="this.editTags=true"></i> 
                        </div>
                        <div class="inLineElementsContainer">
                            <div class="listElement" v-for="tag in this.script.tags" :key="tag">
                                {{tag.name}}
                            </div>
                            
                        </div>
                    </div>

                    <div class="globalOverlay center" v-if="this.editTags" @click="this.editTags=false">
                        <div class="modalEditElements" @click="$event.stopPropagation()">
                            <p class="title">Edit Tags</p>
                            <div class="editList">
                                
                                <div class="editListElement"  :class="{'selected': this.scriptTags.includes(tag.id)}" v-for="tag in this.tags" :key="tag" @click="ToogleCheckTag(tag.id)">
                                    <p>{{tag.name}}</p>

                                </div>
                            </div>
                            <div class="editListButtons">
                                <button class="generalButton primary">Guardar</button>
                            </div>

                        </div>
                        
                    </div>
                </div>

                


            </div>
        </div>

        <div class="userSectionContent" v-else-if="this.currentSection === 1">

            <div class="sectionPanel">
            
                <div class="sectionTitle">Name</div>

                <div class="userInputContainer">

                    <div class="userInput" v-for="lenguage in Object.keys(this.script.name).filter(key => key !== '_id')" :key="lenguage">
                        <p class="label">{{lenguage}}</p>
                        <input type="text" v-model="this.script.name[lenguage]" spellcheck="false" >
                    </div>
                </div>
            </div>

            <div class="sectionPanel">
            
                <div class="sectionTitle">Short Description</div>

                <div class="userInputContainer">

                    <div class="userInput" v-for="lenguage in Object.keys(this.script.shortDesc).filter(key => key !== '_id')" :key="lenguage">
                        <p class="label">{{lenguage}}</p>
                        <input type="text" v-model="this.script.shortDesc[lenguage]" spellcheck="false" >
                    </div>
                </div>
            </div>

            <div class="sectionPanel">
            
                <div class="sectionTitle">Long Description</div>

                <div class="userInputContainer">

                    <div class="userInput" v-for="lenguage in Object.keys(this.script.longDesc).filter(key => key !== '_id')" :key="lenguage">
                        <p class="label">{{lenguage}}</p>
                        <input type="text" v-model="this.script.longDesc[lenguage]" spellcheck="false" >
                    </div>


                </div>
            </div>

            
        </div>

        <div class="userSectionContent" v-else-if="this.currentSection === 2">

            <div class="sectionPanel">
            
                <div class="sectionTitle">LOGS / EVENTS</div>
            </div>
        </div>
    </div>

</template>

<script>
import {GetCategories,GetTags,GetScriptsMetricsById} from '@/helpers/APIconnection'
import {GetTaskImg,FormatDate} from '@/helpers/utils'

export default {

    props: [ 'scriptData' ],
    emits: [ 'back' ],
    data:function(){
        return{
            initialData: undefined,
            script:{},
            scriptMetrics:{},
            currentSection: 0,
            editCategories:false,
            editTags:false,
            editGeneralData:false,
            scriptCategories:[],
            scriptTags:[],
            categories:[],
            
        }
    },
    methods:{

        
        GetTaskImg,
        FormatDate,

        ToogleCheckCategory(categoryID){
            const position = this.scriptCategories.indexOf(categoryID)
            position > -1 ? this.scriptCategories.splice(position,1) : this.scriptCategories.push(categoryID)
        },

        ToogleCheckTag(tagID){
            const position = this.scriptTags.indexOf(tagID)
            position > -1 ? this.scriptTags.splice(position,1) : this.scriptTags.push(tagID)
        },
        OnMenuElementClick: function(menuElement, section){

            this.currentSection = section ?? 0

            if ( !menuElement ) return
            
            this.$refs.menuMark.style.left = menuElement.offsetLeft + 'px'
            this.$refs.menuMark.style.width = menuElement.offsetWidth + 'px'
        },

        

    },
    mounted(){
        this.OnMenuElementClick(this.$refs.initialSection)

    },
    created(){
        this.initialData = {...this.scriptData}
        this.script = JSON.parse(JSON.stringify(this.initialData))

        GetScriptsMetricsById(this.script._id).then((response) => {
            this.scriptMetrics=response.data
          
        }).catch((error) => {
            console.log(error)
        })

        for (const iterator of this.script.categories) {
            
            this.scriptCategories.push(iterator._id)
        }
        for (const iterator of this.script.tags) {
            
            this.scriptTags.push(iterator._id)
        }
        
        

        
        
        GetCategories().then((response)=>{
            this.categories=response.data.categories
        }).catch((error)=> {
            console.log(error)
        })

        GetTags().then((response)=>{
            this.tags=response.data.tags
        }).catch((error)=> {
            console.log(error)
        })
    }

    
}
</script>

<style>

.scriptModal{

    background: #fff;
    padding: 24px 24px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.scriptModal .scriptMainData{
    position: relative;
    display: flex;
    gap: 24px;
}

.scriptModal .scriptMainData .editIcon{
    background: var(--main-10);
    padding: 12px 24px;
    border-radius: 8px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;

}
.userDataAndImg .imageContainer{
    background: var(--main-10);
    border-radius: 16px;
}
.scriptMainData .dataContainer{

    display: flex;
    flex-direction: column;
    gap: 12px;
    
}
.mainData{

}

.scriptMainData .dataContainer .dataObject{

    display: flex;
    
    
}
.scriptMainData .dataContainer .dataObject :first-child{

   width: 150px;
    
}

.scriptModal .modalTitle{
    text-align: center;
    font-size: 32px;

}
.scriptModal .inLineElementsContainer{
    margin-top: 8px;
    display: flex;
    gap: 8px;
}
.sectionPanel .scriptCategories .title, .scriptTags .title{
    
    font-size: 24px;

}
.inLineElementsContainer .listElement{
    position: relative;
    padding: 8px 16px;
    background: var(--main-10);
    border-radius: 4px;
}

/* ---------- GLOBAL OVERLAYS ---------- */

.globalOverlay {
    position: fixed;
    inset: 0;
    backdrop-filter: blur(15px);
    z-index: 500;
    overflow: hidden;
}

.globalOverlay.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.globalOverlay .gifContainer {
    height: 230px;
    width: 300px;
    background: #FAFAFA;
    box-shadow: 0 0 10px 1px var(--main-100);
    border-radius: 56px;
    overflow: hidden;
 
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalEditElements{
 background: #FAFAFA;
 width: 50%;
 padding: 24px;
 border-radius: 16px;
 border: 1px solid var(--main-100);



}
.modalEditElements .title{
    font-size: 24px;
    font-weight: bold;


}

.modalEditElements .editList{
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 12px;
    
    
}
.modalEditElements .editList .editListElement{
    padding: 12px;
    background: var(--main-10);
}

.modalEditElements .editList .editListElement.selected{
    color: #FAFAFA;
    background: var(--main-100);
}

.modalEditElements .editListButtons{
    margin-top: 12px;
    display: flex;
    justify-content: flex-end;
    
}

</style>