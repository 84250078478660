<template>
  <div v-if="this.ticketSelected==undefined">
    <div class="contextBar">
            <p class="currentView">{{this.$route.name}}</p>

            <div class="titleInfoTagsContainer" style="margin-left: 24px" v-if="this.tickets">
                <div class="titleInfoTags" >
                    {{this.tickets.total}} tickets
                </div>
            </div>
        </div>

    <div class="usersListContainer" v-if="!this.loader">

        <div class="responseConfigBar">
                <div class="searchBar">
                    <i class="fi fi-rr-search"></i>
                    <input @input="this.SearchAppTicket()" type="text" spellcheck="false" placeholder="Search ticket" v-model="this.searchText" @focus="$event.currentTarget.parentElement.classList.add('focus')" @blur="$event.currentTarget.parentElement.classList.remove('focus')">
                </div>
                <div class="filters" >
                    <button class="filtersButton" @click="this.showFiltersContainer = !this.showFiltersContainer">
                        <i class="fi fi-rr-filter"></i>
                        Filters
                    </button>

                    <div class="filtersContainer" v-if='this.showFiltersContainer'>
                        <p class='filtersTitle'>Filters</p>

                        <div class="filterLine">
                            <div class="filter">
                                <div class="textFlex">
                                    <p> Created</p>
                                    <p class="spec">From</p>
                                </div>
                                <input type="date" v-model="this.filterValues.dateFrom" v-on:keydown.enter="this.GetTicketsFunction()">
                            </div>

                            <div class="filter" >
                                <div class="textFlex end">
                                    <p class="spec">To</p>
                                </div>
                                <input type="date" placeholder="01/03/2022" v-model="this.filterValues.dateTo" v-on:keydown.enter="this.GetTicketsFunction()">
                            </div>

                        </div>
                        
                        <div class="addFiltersButton">
                            <button class="generalButton primary" @click="this.GetTicketsFunction()">Search</button>
                        </div>
                    </div>
                </div>
            </div>

        <TableHeader :paginationValues="this.paginationValues" :entriesTotal="this.tickets.total" v-on:OnPaginationChange="Object.assign(this.paginationValues, $event); this.GetTicketsFunction()"/>
        <!-- <div v-if="!this.tableLoader"> -->
            <table class="usersTable" v-if="this.tickets">
                    <tr>
                        <th>SUBJECT</th>
                        <th><div @click="this.SwitchFieldSorting(this.sortValues, 'createAt'), this.paginationValues.entriesStart = 0; this.GetTicketsFunction()" :class="this.sortValues['createAt']"> CREATE DATE <i class="fi fi-br-arrow-down"></i></div></th>
                        <th>USER</th>
                        <th><div @click="this.SwitchFieldSorting(this.sortValues, 'status'), this.paginationValues.entriesStart = 0; this.GetTicketsFunction()" :class="this.sortValues['status']"> STATUS <i class="fi fi-br-arrow-down"></i></div></th>
                        <th>REPLIES</th>
                        
                    </tr>

                    <tr v-for="ticket in this.tickets.items" :key="ticket" @click="this.$saveCurrentScroll(this); this.ticketSelected=ticket">
                        <td>
                            <div class="user">
                                <div class="userInfo">
                                    <p class="userName">{{ticket.subjet}}</p>
                                </div>
                            </div>
                        </td>
                        <td class="joinedDate">{{FormatDate(ticket.createAt)}}</td>
                        <td class="joinedDate">{{ticket.user?.name ?? " "}}</td>
                        <td class="ticketStatus"><div :class="this.statusList[ticket.status].toLowerCase()">{{this.statusList[ticket.status]}}</div></td>
                        <td class="joinedDate">{{ticket.replies.length}}</td>
                    </tr>   
                </table>
            <!-- </div>
            <div v-else style="height:400px"><div class="bigLoadingSpinner main center"></div></div> -->
    </div>
    <div v-else class="bigLoadingSpinner main center"></div>
    


  </div>
  <TicketView v-else :ticketData="this.ticketSelected" v-on:back="ticketSelected=undefined; this.$backToPreviousScroll(this)" />
</template>

<script>
import {GetTickets, SearchFunction} from '@/helpers/APIconnection'
import { FormatDate, SwitchFieldSorting } from '@/helpers/utils'
import TicketView from '@/components/TicketView'
import TableHeader  from '@/components/TableHeader'
export default {

    components:{TicketView, TableHeader},

    data:function(){
        return{
            tickets:{},
            ticketSelected:undefined,
            statusList:['PENDING','IN PROGRESS','FINALIZED'],
            searchText:'',
            loader:true,
            showFiltersContainer:false,
            tableLoader:false,
            paginationValues:  {
                entriesLimit: 100,
                entriesStart: 0,
            },
            sortValues: {createAt: 'desc'},
            filterValues: {},

        }
    },

    created(){
        GetTickets(this.filterValues, this.sortValues, this.paginationValues.entriesStart, this.paginationValues.entriesLimit).then((response) => {
            this.tickets=response.data
            this.loader=false
        }).catch((error) => {
            console.log(error.response)
        })
    },

    methods:{
        FormatDate,
        SwitchFieldSorting,

        GetTicketsFunction:function(){
            this.tableLoader=true
            this.showFiltersContainer=false
            GetTickets(this.filterValues, this.sortValues, this.paginationValues.entriesStart, this.paginationValues.entriesLimit).then((response) => {       
                this.tickets=response.data
                this.tableLoader=false
            }).catch((error) => {
                console.log(error)
                this.tableLoader=false
            })

        },

        SearchAppTicket:function(){
            
            clearTimeout(this.searchTimeOut)

            if(this.searchText==''){
                this.GetTicketsFunction()
                return}
    
                this.searchTimeOut = setTimeout(() => { 
                
                SearchFunction('tickets',this.searchText).then((response) => {
                    
                    this.tickets=response.data
                }).catch((error)=> {
                    console.log(error)
                    this.GetTicketsFunction()
                })

            },500)

        },

        SetStatus(status){
            return this.statusList[status]

        }
    }

}
</script>

<style>

.ticketStatus div{
    height: 32px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(231, 195, 18);
    color: white;
    font-size: 12px;
    font-weight: 700;
    border-radius: 4px;
    user-select: none;
}
.ticketStatus div.pending{
    
    background: var(--main-10);
    background: rgb(250, 36, 36);
}
.ticketStatus div.finalized{
    
    background: var(--main-10);
    background: rgb(87, 233, 8);
}

</style>